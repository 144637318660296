import React, { useEffect, useState } from "react";
import {
  LoadingIndicator,
  SvgIcon,
  SvgNames,
  tokens,
  Typography,
} from "@sunrun/experience-ui-components";
import styled from "@emotion/styled/macro";
import {
  SplatContact,
  SplatLoan,
} from "../../../../amplify/backend/function/OfferExpContacts/ts/public/types";
import { CheckoutFooter } from "../molecules/CheckoutFooter";
import { TaskHeader } from "../molecules/TaskHeader";
import { ROLES } from "../../../utils/usageUtils";
import { UnknownOdiModal } from "../molecules/UnknownOdiModal";
import { DocumentCard } from "./DocumentCard";
import { useCheckoutContext } from "checkout/providers/CheckoutContext";
import { useReadSignDocs } from "checkout/hooks/useReadSignDocs";
import { useContactsData } from "utils/swrHooks";
import { useAppSelector } from "store";
import { HyperlinkButton } from "components/atoms/HyperlinkButton";
import documentIcon from "assets/images/document.svg";
import { useLoans } from "checkout/hooks/useCheckoutSwr";
import { getPrimaryContact } from "utils/contacts";

const ReadAndSignDocsTaskView: React.FC = () => {
  const hybridToken = useAppSelector((state) => state?.auth?.hybridToken);
  const {
    tasksLoading,
    currentTask,
    proposalId,
    prospectId,
    refreshTasks,
    navigateToNextTask,
  } = useCheckoutContext();
  const { readSignDocsSteps } = useReadSignDocs({
    prospectId,
    proposalId,
  });
  const [showUnknownOdisModal, setShowUnknownOdisModal] = useState(true);
  const unknownOdis = readSignDocsSteps?.reduce(
    (stepNames, step) =>
      step.isUnknown ? [...stepNames, step.docName] : stepNames,
    [] as string[]
  );
  const { contacts, isContactsLoading } = useContactsData(
    prospectId,
    hybridToken
  );
  const [secondaryContact, setSecondaryContact] = useState<
    SplatContact | undefined
  >();

  useEffect(() => {
    if (contacts) {
      setSecondaryContact(
        contacts.find((c) => c.derivedRoles.includes(ROLES.CONTRACTCOSIGNER))
      );
    }
  }, [contacts]);

  const [showOptionalSteps, setShowOptionalSteps] = useState(false);

  const enableContinue = readSignDocsSteps
    ?.filter((doc) => doc.isRequired)
    .every((doc) => doc.completed);

  const [coborrower, setCoborrower] = useState<SplatContact | undefined>();
  const { loans } = useLoans(prospectId, hybridToken);
  useEffect(() => {
    if (loans && loans.length) {
      const approvedLoan: SplatLoan | undefined = loans.find(
        (loan) => loan.Credit_Results__c === "Approved"
      );
      setCoborrower(
        contacts?.find(
          (contact) =>
            contact.contactId === approvedLoan?.Secondary_Loan_Signer__c
        )
      );
    }
  }, [loans, contacts]);

  const selectedContacts =
    readSignDocsSteps?.reduce((arr, curr) => {
      if (arr?.[curr.primarySignerRole] || curr.isUnknown) return arr;

      return {
        ...arr,
        [curr.primarySignerRole]: contacts?.find((contact) =>
          contact.derivedRoles.includes(curr.primarySignerRole)
        ),
      };
    }, {} as Record<string, SplatContact | undefined>) || {};

  if (!currentTask || isContactsLoading) {
    return (
      <LoadingWrapper>
        <LoadingIndicator color="black" />
      </LoadingWrapper>
    );
  }

  if (Object.values(selectedContacts).some((contact) => !contact)) {
    // Should never happen as "Confirm Names" step is before this one
    throw new Error("Missing contact");
  }

  const toggleShowOptionalSteps = () => {
    setShowOptionalSteps((prevShowOptionalSteps) => !prevShowOptionalSteps);
  };

  const ipaStep = readSignDocsSteps?.find(
    (step) => step.odiName === "ipaRebate"
  );
  const cpucStep = readSignDocsSteps?.find((step) => step.odiName === "cpuc");
  const customerAgreementStep = readSignDocsSteps?.find(
    (step) => step.odiName === "customerAgreement"
  );

  const optionalSteps = readSignDocsSteps?.filter((step) => step.isOptional);

  return (
    <>
      <Wrapper data-testid="read-sign-docs-task-view">
        {unknownOdis && unknownOdis.length > 0 && showUnknownOdisModal && (
          <UnknownOdiModal
            odiNames={unknownOdis}
            onClose={() => {
              setShowUnknownOdisModal(false);
            }}
          />
        )}
        <Typography>
          Please read the agreements carefully. You will then be redirected to
          Docusign for signature. It may take several minutes to update. If you
          already signed, please{" "}
          <HyperlinkButton onClick={refreshTasks}>
            refresh this page.
          </HyperlinkButton>
        </Typography>
        {tasksLoading && <LoadingIndicator color="black" />}
        {/* Render Cpuc Doc */}
        {!tasksLoading && cpucStep && (
          <DocumentCard
            key={cpucStep.odiName}
            primaryContact={selectedContacts?.[cpucStep.primarySignerRole]!}
            secondaryContact={secondaryContact}
            setSecondaryContact={setSecondaryContact}
            refreshTasks={refreshTasks}
            {...cpucStep}
          />
        )}
        {/* Render IL IPA */}
        {!tasksLoading && ipaStep && (
          <DocumentCard
            key={ipaStep?.odiName}
            primaryContact={getPrimaryContact(contacts)!}
            refreshTasks={refreshTasks}
            {...ipaStep}
          />
        )}
        {/* Render Customer Agreement Doc */}
        {!tasksLoading && customerAgreementStep && (
          <DocumentCard
            key={customerAgreementStep.odiName}
            primaryContact={
              selectedContacts?.[customerAgreementStep.primarySignerRole]!
            }
            secondaryContact={secondaryContact}
            setSecondaryContact={setSecondaryContact}
            refreshTasks={refreshTasks}
            {...customerAgreementStep}
          />
        )}
        {/* Render Docs ODIs */}
        {!tasksLoading &&
          readSignDocsSteps
            ?.filter(
              (step) =>
                step.odiName !== "ipaRebate" &&
                step.odiName !== "customerAgreement" &&
                step.odiName !== "cpuc" &&
                !step.isOptional &&
                !step.isUnknown
            )
            .map((step, index) => (
              <DocumentCard
                key={index}
                primaryContact={selectedContacts?.[step.primarySignerRole]!}
                secondaryContact={
                  step.odiName === "loanDocumentSignature"
                    ? coborrower
                    : undefined
                }
                refreshTasks={refreshTasks}
                {...step}
              />
            ))}
        {!tasksLoading && optionalSteps && optionalSteps.length > 0 && (
          <OptionalStepsWrapper>
            <OptionalStepsHeaderWrapper>
              <TaskHeader
                icon={documentIcon}
                label={"Optional Supplementary Documents"}
              />
              <div
                onClick={toggleShowOptionalSteps}
                data-testid="checkout-read-and-sign-net-task-metering-header-carrot"
              >
                <SvgIcon
                  name={
                    showOptionalSteps
                      ? SvgNames.ChevronUp
                      : SvgNames.ChevronDown
                  }
                  width="24"
                  height="24"
                  color={tokens.BLACK}
                  style={{ marginTop: "24px" }}
                />
              </div>
            </OptionalStepsHeaderWrapper>
            {showOptionalSteps &&
              optionalSteps.map((step) => (
                <DocumentCard
                  key={step.odiName}
                  primaryContact={selectedContacts?.[step.primarySignerRole]!}
                  secondaryContact={undefined}
                  refreshTasks={refreshTasks}
                  {...step}
                />
              ))}
          </OptionalStepsWrapper>
        )}
      </Wrapper>
      <CheckoutFooter
        buttonText="Confirm"
        isDisabled={!enableContinue}
        onClick={navigateToNextTask}
      />
    </>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  margin-bottom: 145px;
`;

const LoadingWrapper = styled.div`
  margin-top: 32px;
`;

const OptionalStepsWrapper = styled.div`
  border-top: 1px solid ${tokens.BORDER_LIGHT};
  display: flex;
  flex-direction: column;
  gap: 32px;
`;

const OptionalStepsHeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export { ReadAndSignDocsTaskView };

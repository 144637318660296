import React, { useCallback, useEffect, useState } from "react";
import { Snackbar } from "@sunrun/experience-ui-components";
import { PaymentIFrame } from "../../../checkout/components/organisms/PaymentIframeContainer";
import { SuccessfulPayment } from "../../../checkout/components/molecules/SuccessfulPayment";
import { SplatContact } from "../../../../amplify/backend/function/OfferExpContacts/ts/public/types";
import {
  MessageType,
  Outbound_AutopaySuccess_MessagePayload,
  OutboundLog,
} from "../../../checkout/types/payments-ui-form";
import { SplatProspectResponse } from "../../../../amplify/backend/function/OfferExpUsage/ts/public/types";
import { rollbar } from "../../../providers/rollbar";

type AutopayEnrollmentParams = {
  prospect: SplatProspectResponse;
  contacts: SplatContact[];
  paymentType: any;
  autopayPaymentAmount: string;
  showBankForm: boolean;
  showForm: boolean;
  showSuccess: boolean;
  setShowForm: (show: boolean) => void;
  setSuccessResponse: (response: any) => void;
};
const AutopayEnrollmentForm: React.FC<AutopayEnrollmentParams> = ({
  prospect,
  contacts,
  paymentType,
  autopayPaymentAmount,
  showBankForm,
  showForm,
  setShowForm,
  showSuccess,
  setSuccessResponse,
}) => {
  const [autopayResults, setAutopayResults] =
    useState<Outbound_AutopaySuccess_MessagePayload>();
  const [showSuccessSnackbar, setShowSuccessSnackbar] = useState(false);

  const messageHandler = useCallback((message: MessageEvent) => {
    if (message?.data.type === MessageType.OUTBOUND_AUTOPAY_SUCCESS) {
      const payload = message.data
        .payload as Outbound_AutopaySuccess_MessagePayload;
      setAutopayResults(payload);
      setShowSuccessSnackbar(true);
    } else if (message?.data.type === MessageType.OUTBOUND_LOG) {
      const payload = message.data as OutboundLog;
      rollbar.log(payload);
    }
  }, []);

  useEffect(() => {
    window.addEventListener("message", messageHandler);

    return () => {
      window.removeEventListener("message", messageHandler);
    };
  }, [messageHandler]);

  const autopaySuccessCallback = useCallback(() => {
    setShowSuccessSnackbar(false);
    setSuccessResponse(autopayResults);
  }, [autopayResults, setSuccessResponse]);

  return (
    <>
      {!showSuccess && showForm && (
        <PaymentIFrame
          contacts={contacts}
          prospect={prospect}
          paymentMethod={paymentType}
          autopay
          cancelPayment={() => setShowForm(false)}
        />
      )}

      {showSuccess && (
        <SuccessfulPayment
          autoPay
          paymentAmount={autopayPaymentAmount}
          customerEmail={prospect.customerEmail ?? ""}
          lastFour={autopayResults?.lastFour}
          paymentType={paymentType}
        />
      )}
      <Snackbar
        open={showSuccessSnackbar}
        autoHideDuration={4000}
        colorScheme="light"
        onClose={autopaySuccessCallback}
        type="success"
        message={`Autopay confirmed. Enrolled with ${paymentType} account ending in ${autopayResults?.lastFour}`}
      />
    </>
  );
};

export { AutopayEnrollmentForm };

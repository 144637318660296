import React, { useCallback, useEffect, useState } from "react";
import {
  Button,
  Card,
  CardHeader,
  LoadingIndicator,
  Modal,
  Snackbar,
  tokens,
  Tooltip,
  Typography,
} from "@sunrun/experience-ui-components";
import styled from "@emotion/styled/macro";
import { JSX } from "react/jsx-runtime";
import { useCheckoutContext } from "../../providers/CheckoutContext";
import { RadioCard } from "../atoms/RadioCard";
import { ACHWarningBanner } from "../atoms/ACHWarningBanner";
import { useAppSelector } from "../../../store";
import { useProspect } from "../../hooks/useCheckoutSwr";
import { useContactsData } from "../../../utils/swrHooks";
import {
  MessageType,
  Outbound_AutopaySuccess_MessagePayload,
  OutboundLog,
  PayMethod,
} from "../../types/payments-ui-form";
import { SuccessfulPayment } from "../molecules/SuccessfulPayment";
import { CheckoutFooter } from "../molecules/CheckoutFooter";
import {
  ReviewFlexMonthlySummary,
  ReviewMonthlySummary,
} from "../../../../amplify/backend/function/offerexpstoreFrontApi/ts/public/offerTypes";
import infoButton from "../../../components/assets/images/info-icon-blue.svg";
import { formatCurrency } from "../../util/constants";
import { rollbar } from "../../../providers/rollbar";
import { SlimContactCard } from "../molecules/SlimContactCard";
import { SplatContact } from "../../../../amplify/backend/function/OfferExpContacts/ts/public/types";
import { postPaymentRequestEmail } from "../../../services/prospect";
import { PaymentIFrame } from "./PaymentIframeContainer";

const AutopayEnrollTaskView: React.FC = () => {
  const authKey = useAppSelector((state) => state?.auth?.hybridToken);
  const {
    offer,
    navigateToNextTask,
    refreshTasks,
    prospectId,
    currentTask,
    splatRole,
  } = useCheckoutContext();
  const [showModal, setShowModal] = useState(false);
  const [showSuccessSnackbar, setShowSuccessSnackbar] = useState(false);
  const [showEmailSuccessSnackbar, setShowEmailSuccessSnackbar] =
    useState(false);
  const [paymentType, setPaymentType] = useState(PayMethod.CHECKING);

  const [showBankForm, setShowBankForm] = useState(false);
  const [autopayResults, setAutopayResults] =
    useState<Outbound_AutopaySuccess_MessagePayload>();
  const [showAutopayEmailConfirmation, setShowAutopayEmailConfirmation] =
    useState(false);
  const [emailLoading, setEmailLoading] = useState(false);
  const { contacts } = useContactsData(prospectId!!, authKey);
  const { data: prospect } = useProspect(prospectId);

  const primaryContact = contacts?.find(
    (contact: SplatContact) => contact.primary
  );

  const TOOLTIP_CONTENT =
    "With Sunrun Flex, your monthly bill can vary. It will range from the minimum monthly amount up to the maximum amount, as you increase your usage.";

  const showACHWarning = splatRole === "Integrated Partner";

  const sendEmailCallback = useCallback(async () => {
    setEmailLoading(true);
    let result;

    try {
      result = await postPaymentRequestEmail(authKey, prospectId, "Recurring");
    } catch (e: any) {
      rollbar.error(e);
    } finally {
      setShowAutopayEmailConfirmation(false);
      setEmailLoading(false);
      if (result?.message === "Email sent successfully") {
        setShowEmailSuccessSnackbar(true);
      }
    }
  }, [authKey, prospectId]);

  const messageHandler = useCallback(
    (message: MessageEvent) => {
      if (message?.data.type === MessageType.OUTBOUND_AUTOPAY_SUCCESS) {
        setShowBankForm(false);
        const payload = message.data
          .payload as Outbound_AutopaySuccess_MessagePayload;
        setAutopayResults(payload);
        setShowSuccessSnackbar(true);
        refreshTasks();
      } else if (message?.data.type === MessageType.OUTBOUND_LOG) {
        const payload = message.data as OutboundLog;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const rollbarLogFunction =
          (rollbar as any)[payload.logLevel] || rollbar.info;
        rollbarLogFunction.apply(rollbar, [
          `Experience Payments UI: ${payload.message}`,
          payload.params,
        ]);
      }
    },
    [refreshTasks]
  );

  useEffect(() => {
    window.addEventListener("message", messageHandler);

    return () => {
      window.removeEventListener("message", messageHandler);
    };
  }, [messageHandler]);

  let offerFinancialSummary;
  let monthlyPayment: string | undefined;
  let showTooltip = false;
  if (offer?.financial) {
    switch (offer.financial.financePlan) {
      case "Monthly Plan":
        offerFinancialSummary = offer.financial.summary as ReviewMonthlySummary;
        monthlyPayment = formatCurrency(offerFinancialSummary.firstYearMonthly);
        break;
      case "Sunrun Flex":
        showTooltip = true;
        offerFinancialSummary = offer.financial
          .summary as ReviewFlexMonthlySummary;
        monthlyPayment = `${formatCurrency(
          offerFinancialSummary.minimumMonthlyPayment
        )} - ${formatCurrency(offerFinancialSummary.maximumMonthlyPayment)}`;
        break;
    }
  }

  const handleSkipAutopay = useCallback(() => {
    setShowModal(true);
  }, []);

  const PaymentTypeRadioButtons: JSX.Element[] = [];
  const createRadioOptionsFromPaymentMethods = (
    capitalizedLabel: string,
    valueForEnum: string
  ) => {
    PaymentTypeRadioButtons.push(
      <RadioCard
        key={valueForEnum}
        labelElement={
          <LabelContainer role="label">
            <Typography
              size={tokens.FONT_SIZE_3}
              color={tokens.BRAND_HEROBLUE}
              fontWeight="medium"
            >
              {capitalizedLabel}
            </Typography>
            <Typography
              color={tokens.TINTS_OFF_WHITE_20}
              size={tokens.FONT_SIZE_3}
            >
              Recurring bank transfer
            </Typography>
          </LabelContainer>
        }
        checked={paymentType === valueForEnum}
        handleChange={() => setPaymentType(valueForEnum as PayMethod)}
      />
    );
  };

  const PaymentMethods = new Map([
    [PayMethod.CHECKING, "Checking"],
    [PayMethod.SAVINGS, "Savings"],
  ]);
  PaymentMethods.forEach(createRadioOptionsFromPaymentMethods);

  if (currentTask?.isComplete && monthlyPayment) {
    return (
      <>
        <SuccessfulPayment
          autoPay
          odiComplete
          paymentAmount={monthlyPayment}
          customerEmail={prospect?.customerEmail}
        />
        <CheckoutFooter buttonText={"Continue"} onClick={navigateToNextTask} />
      </>
    );
  }

  const EmailConfirmationContent = () => (
    <>
      <Typography>
        The autopay form email will be sent to the email address below.
      </Typography>
      {primaryContact && (
        <SlimContactCard showEmail={true} contact={primaryContact} />
      )}
    </>
  );

  const MonthlyPayment = () => (
    <Typography
      size={tokens.FONT_SIZE_5}
      color={tokens.BRAND_HERO_BLUE}
      fontWeight={"medium"}
      testId="checkout-autopay-without-autopay"
    >
      {monthlyPayment}/mo
    </Typography>
  );

  const MonthlyPaymentWithTooltip = () => (
    <StyledTooltip content={TOOLTIP_CONTENT}>
      <MonthlyPayment />
      <img alt="info icon tooltip" src={infoButton}></img>
    </StyledTooltip>
  );

  const forMoreDetailsText = (
    <Typography size={tokens.FONT_SIZE_1} color={tokens.TEXT_DARK}>
      For exact discount details, please refer to your customer agreement. If
      you have questions, please visit{" "}
      <InlineLink href="https://www.sunrun.com/autopay" target="_blank">
        www.sunrun.com/autopay
      </InlineLink>
    </Typography>
  );

  return (
    <>
      {showACHWarning && (
        <ACHWarningBanner
          preferredLanguage={prospect?.preferredLanguage || "English"}
        />
      )}
      {!showBankForm && !autopayResults && (
        <StyledGrid>
          <Typography size={tokens.FONT_SIZE_1} color={tokens.TEXT_DARK}>
            Your quoted payment includes the discount you receive when you sign
            up for ACH autopay. For certain agreement types, autopay enrollment
            is required before your system can be approved and submitted for
            city permitting.
          </Typography>
          {forMoreDetailsText}
          <Card>
            <CardHeader>
              <Typography
                size={tokens.FONT_SIZE_2}
                color={tokens.BRAND_HERO_BLUE}
                fontWeight={"medium"}
              >
                Autopay Details
              </Typography>
            </CardHeader>
            <div>
              <Typography
                size={tokens.FONT_SIZE_2}
                color={tokens.BRAND_HERO_BLUE}
                fontWeight={"medium"}
              >
                Autopay amount
              </Typography>
              {showTooltip ? <MonthlyPaymentWithTooltip /> : <MonthlyPayment />}
              <Typography size={tokens.FONT_SIZE_0} color={tokens.TEXT_LIGHT}>
                Price includes discount for enrolling in ACH autopay.
              </Typography>
            </div>

            <Typography
              className="payment-method"
              size={tokens.FONT_SIZE_2}
              color={tokens.BRAND_HEROBLUE}
            >
              Payment Method
            </Typography>
            <StyledGridInsideCard>
              {PaymentTypeRadioButtons.map(
                (PaymentTypeRadioButton) => PaymentTypeRadioButton
              )}
              <FlexEndButtonContainer>
                <StyledButtonSmPadding
                  disabled={paymentType.length < 0}
                  onClick={() => {
                    setShowBankForm(true);
                  }}
                  size="sm"
                  data-testid="offer-experience--AutopayEnrollTaskView--PaymentMethod-button"
                >
                  Continue with autopay
                </StyledButtonSmPadding>
              </FlexEndButtonContainer>
            </StyledGridInsideCard>
          </Card>
        </StyledGrid>
      )}
      {showBankForm && prospect && !!contacts?.length && (
        <PaymentIFrame
          contacts={contacts}
          prospect={prospect}
          autopay
          paymentMethod={paymentType}
          cancelPayment={() => setShowBankForm(false)}
        />
      )}
      {autopayResults?.lastFour && prospect && monthlyPayment && (
        <SuccessfulPayment
          autoPay
          paymentAmount={monthlyPayment}
          customerEmail={prospect.customerEmail ?? ""}
          lastFour={autopayResults.lastFour}
          paymentType={paymentType}
        />
      )}
      {showModal && (
        <Modal
          type="warning"
          title="Agree to give up autopay discount?"
          onClose={() => setShowModal(false)}
          primaryButton={{
            text: "Cancel",
            onClick: () => setShowModal(false),
            testId: "checkout-autopay-skip-modal-cancel",
          }}
          secondaryButton={{
            text: "Skip autopay",
            onClick: navigateToNextTask,
            testId: "checkout-autopay-skip-modal-skip",
          }}
        >
          <Typography>
            Not enrolling in ACH Autopay will result in a higher monthly bill as
            the customer will lose the ACH autopay discount.
          </Typography>
          <Typography> Are you sure you want to skip autopay?</Typography>
        </Modal>
      )}
      {showAutopayEmailConfirmation && (
        <Modal
          hideIcon={true}
          title="Email autopay form"
          onClose={() => setShowAutopayEmailConfirmation(false)}
          secondaryButton={{
            text: "Cancel",
            onClick: () => setShowAutopayEmailConfirmation(false),
            testId: "checkout-autopay-skip-modal-cancel",
          }}
          primaryButton={{
            disabled: emailLoading,
            text: "Send email",
            onClick: sendEmailCallback,
            testId: "checkout-autopay-skip-modal-skip",
          }}
        >
          {emailLoading ? (
            <LoadingIndicator color="black" showLoadingMessage={false} />
          ) : (
            <EmailConfirmationContent />
          )}
        </Modal>
      )}
      <CheckoutFooter
        buttonText={autopayResults?.lastFour ? "Continue" : "Skip Autopay"}
        onClick={() =>
          autopayResults?.lastFour ? navigateToNextTask() : handleSkipAutopay()
        }
        testId="checkout-autopay-skip"
      >
        <StyledSecondaryButton
          type="submit"
          onClick={() => {
            setShowAutopayEmailConfirmation(true);
          }}
        >
          Email Autopay
        </StyledSecondaryButton>
      </CheckoutFooter>
      <Snackbar
        open={showEmailSuccessSnackbar}
        autoHideDuration={4000}
        colorScheme="light"
        message={`Email sent successfully!`}
        onClose={() => setShowEmailSuccessSnackbar(false)}
        type="success"
      />
      <Snackbar
        open={showSuccessSnackbar}
        autoHideDuration={4000}
        colorScheme="light"
        message={`Autopay confirmed. Enrolled with ${paymentType} account ending in ${autopayResults?.lastFour}.`}
        onClose={() => setShowSuccessSnackbar(false)}
        type="success"
      />
    </>
  );
};

const StyledGridInsideCard = styled.div`
  display: grid;
  gap: 16px;
`;

const StyledGrid = styled.div`
  display: grid;
  gap: 24px;
`;

const StyledButtonSmPadding = styled(Button)`
  padding: 8px 48px;
`;

const StyledSecondaryButton = styled(Button)`
  background: inherit;
  border: 1px solid ${tokens.BRAND_HERO_BLUE};
  color: ${tokens.BRAND_HERO_BLUE};
  font-size: 16px;
  font-family: "Roobert", serif;
  font-weight: 500;
  line-height: 24px;
  word-wrap: break-word;
  margin-right: 16px;
  padding-left: 32px;
  padding-right: 32px;
`;

const FlexEndButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  padding-top: 24px;
`;

const LabelContainer = styled.div`
  display: flex;
  gap: 8px;
`;

const InlineLink = styled.a`
  text-decoration: underline;
  font-weight: 500;
  font-size: 16px;
  cursor: pointer;
  margin-right: 10px;
  color: ${tokens.BRAND_HERO_BLUE};
`;

const StyledTooltip = styled(Tooltip)`
  div.sr-tooltip-children {
    display: flex;
    gap: 4px;
  }
`;

export { AutopayEnrollTaskView };

type DescriptionException = {
  description: string;
  exception: string;
};

interface LineItemDescription {
  [key: string]: DescriptionException;
}

const SowLineItemDescriptions: LineItemDescription[] = [
  {
    "Main Panel Upgrade (MPU)": {
      description: "Increase to the size of the service panel",
      exception:
        "Replacement of service conductors from the service equipment to the utility's first point of connection",
    },
  },
  {
    "Main Panel Replacement (MPR)": {
      description:
        "Replacement of the service panel with a new service panel (with a new panel of the same size as the original panel, if possible)",
      exception:
        "Replacement of service conductors from the service equipment to the utility's first point of connection",
    },
  },
  {
    "Sunrun EV Charger and Install": {
      description: "Hardware and installation of the Sunrun EV Charger",
      exception: "Work outside of the Sunrun EV Charger and Install",
    },
  },
  {
    "SPAN Main Panel": {
      description: "Installation of a new SPAN smart panel as the main panel",
      exception: "Work outside of the SPAN Main Panel",
    },
  },
  {
    "SPAN Sub-Panel": {
      description: "Installation of a new SPAN smart panel as a subpanel",
      exception: "Work outside of the SPAN Sub-Panel",
    },
  },
  {
    "Panel Relocation Only": {
      description: "Relocation of the main electrical panel",
      exception: "Work outside of the relocation of the electrical panel",
    },
  },
  {
    "Meter Adapter": {
      description: "Installation of an adapter",
      exception:
        "Replacement or upgrade of equipment not related to the meter adapter",
    },
  },
  {
    "J-Box": {
      description:
        "Installation of a small electrical junction box near existing utility electrical equipment",
      exception:
        "Improvements to or refurbishments of other electrical equipment",
    },
  },
  {
    "Motor Soft Starter": {
      description:
        "Installation of a motor soft starter unit on each major appliance",
      exception:
        "Work outside of that which is required to install the motor soft starter(s)",
    },
  },
  {
    "Generation Panel": {
      description:
        "Installation of a separate subpanel, which will be dedicated exclusively to the sources of generation (solar panels and battery) in the BrightBox system",
      exception: "Upgrades to the existing electrical service panel(s)",
    },
  },
  {
    "Load Relocation": {
      description:
        "Relocation of any electrical circuits (and their associated breakers) from the main electrical service panel to a new subpanel connected directly to the Brightbox system",
      exception: "Upgrades to the existing electrical service panel(s)",
    },
  },
  {
    "Service Feeder Upgrade": {
      description:
        "Upgrade (to the minimum size requirement for installation) to the service conductors from the service equipment to the utility's first point of connection",
      exception:
        "Replacement of other equipment or services that may not be necessary to go solar",
    },
  },
  {
    "Service Conductor Refeed": {
      description:
        "Installation of new wiring and/or conduit from the service meter to the service panel",
      exception:
        "Replacement or upgrade of any equipment not required to perform the refeed",
    },
  },
  {
    "Knob and Tube Removal": {
      description: "Replacement of knob & tube wiring",
      exception:
        "Work outside of required knob & tube wiring replacement (regardless of whether the wiring is live or dead)",
    },
  },
  {
    "Transformer Upgrade": {
      description:
        "Upgrade (performed by utility) of the utility transformer feeding the home",
      exception:
        "Work by Sunrun to perform the transformer upgrade (as transformer upgrades may only be performed by the utility company)",
    },
  },
  {
    "Derate Main Breaker": {
      description:
        "Reduction to the size of the main breaker in the service panel",
      exception:
        "Replacement or upgrade of equipment not related to derating the main breaker",
    },
  },
  {
    "Derate Sub Breaker": {
      description:
        "Reduction to the size of the sub breaker in the service panel",
      exception:
        "Replacement or upgrade of equipment not related to derating the sub breaker",
    },
  },
  {
    "Derate Fee": {
      description:
        "Payment of a fee (issued by utility in utility bill) for removal of the derate gold band",
      exception: "",
    },
  },
  {
    Sistering: {
      description:
        "Reinforcement of the roof to support the weight of the solar system",
      exception:
        "Remediation of any vermiculite and/or other asbestos-containing material (prior to attic work)",
    },
  },
  {
    "Collar Ties": {
      description:
        "Reinforcement of the roof to support the weight of the solar system",
      exception:
        "Replacement or upgrade of equipment not related to installing the collar ties",
    },
  },
  {
    "Purlin Support": {
      description:
        "Reinforcement of the roof to support the weight of the solar system",
      exception:
        "Replacement or upgrade of equipment not related to installing the purlins",
    },
  },
  {
    Struts: {
      description:
        "Reinforcement of the roof to support the weight of the solar system",
      exception:
        "Replacement or upgrade of equipment not related to installing the rafter struts",
    },
  },
  {
    "Localized Roof Work": {
      description:
        "Improvement to an area of roof surface totaling up to 100 square feet in a localized area, including",
      exception: "Any",
    },
  },
  {
    "Multi-Square": {
      description:
        "Improvement to an area of roof surface totaling greater than 100 square feet across multiple planes, including",
      exception: "Any",
    },
  },
  {
    "Multi-Square Roof Work": {
      description:
        "Improvement to an area of roof surface totaling greater than 100 square feet across multiple planes, including",
      exception: "Any",
    },
  },
  {
    "Entire Home Roof Work": {
      description:
        "Improvement to an area of roof surface totaling greater than 100 square feet across all roof planes, including",
      exception: "Any",
    },
  },
  {
    "Dig Alert": {
      description:
        "Identification and marking of underground service feeders by your utility provider or third party locator, including but not limited to electrical, gas, water, sewer and communications prior to solar installation. Customer should not move or modify flagging or markers placed on their property prior to solar installation. Sunrun installation staff will remove all flags after work is complete and painted markers will wash away naturally.",
      exception:
        "Work outside of identifying and marking underground service feeders",
    },
  },
  {
    "Relocate Satellite Dish": {
      description: "Relocation or removal of satellite dish(es)",
      exception:
        "Modification of satellite dish wiring to accommodate relocation",
    },
  },
  {
    "Solar Thermal Removal": {
      description:
        "Removal of existing roof-mounted solar thermal system and rerouting or capping off pool plumbing",
      exception: "Work outside of the solar thermal removal",
    },
  },
  {
    Trenching: {
      description:
        "Installation of an underground pathway for electrical wiring (aka trenching)",
      exception:
        "Trenching through material other than dirt or backfill (e.g. concrete) over distances greater than 200 ft or across septic system, leach field, or vegetation (e.g. tree or shrub)",
    },
  },
  {
    Boring: {
      description:
        "Installation of underground electrical wiring via boring (trenchless, minimal impact)",
      exception:
        "Boring through material other than dirt or backfill (e.g. concrete) over distances greater than 200 ft or across septic system, leach field, or vegetation (e.g. tree or shrub)",
    },
  },
  {
    "Critter Guard": {
      description:
        "Installation of Critter Guard to prevent animal infestation around/under the solar panels",
      exception: "Work outside of the requirement to install Critter Guard",
    },
  },
  {
    "Gas Meter Relocation": {
      description: "Relocation of the gas meter",
      exception: "Work outside of the required gas meter relocation",
    },
  },
  {
    "NEC Working Clearances - Permanent": {
      description:
        "Removal or relocation of items around electrical and/or gas equipment (typically by a clearance of 3'x3')",
      exception:
        "Work outside of requirement to remedy NEC Working Clearance violation(s)",
    },
  },
  {
    "NEC Working Clearances": {
      description:
        "Removal or relocation of items around electrical and/or gas equipment (typically by a clearance of 3'x3')",
      exception:
        "Work outside of requirement to remedy NEC Working Clearance violation(s)",
    },
  },
  {
    "Asbestos Present - Branch to take appropriate action": {
      description:
        "Remediation of asbestos contaminated materials (vermiculite)",
      exception: "Replacement of remediated material",
    },
  },
  {
    "Unpermitted Structure(s)": {
      description:
        "Acquisition of permit(s) for unpermitted structure(s) and/or resolution of issue(s) with unpermitted structure(s)",
      exception: "Work unrelated to permitting",
    },
  },
  {
    "Other Code Violation": {
      description:
        "Resolution of one or more existing code violations (prior to submitting for final inspection):",
      exception: "Work outside of requirement to remediate code violation(s)",
    },
  },
  {
    "Pest Removal": {
      description: "Remediation or removal of pests",
      exception: "Work outside of requirement to remove and/or remediate pests",
    },
  },
  {
    "Tree Removal": {
      description:
        "Removal of tree(s) that would (with growth) inhibit the contracted solar production specified by Sunrun",
      exception: "Work outside the required tree removal",
    },
  },
  {
    "Ground Mounts": {
      description:
        "Installation of equipment associated with a ground mounted system",
      exception: "Work outside the installation of ground mount equipment",
    },
  },
  {
    "Screwed-In Tile": {
      description:
        "Within the area where the solar panels will be installed, some roof tiles will be removed and replaced. This will allow ourInstallation crew to install flashing that will protect your roof from water intrusion",
      exception: "Work outside of the area where panels will be installed",
    },
  },
  {
    "Vent Work": {
      description:
        "Vents on your roof will be moved or re-routed to a different location to accommodate the solar panel placement of your solar system",
      exception: "Work outside of the area where panels will be installed",
    },
  },
  {
    "Other Work": {
      description: "Completion of other work:",
      exception: 'Work not required for to complete the "other work"',
    },
  },
];

export { SowLineItemDescriptions };

import styled from "@emotion/styled/macro";
import { tokens, Typography } from "@sunrun/experience-ui-components";
import React from "react";

const ContentLeft = styled.div`
  display: flex;
  width: 50%;
  justify-content: flex-start;
`;

const CenteredOneThirdColumn = styled.div`
  display: flex;
  width: 33%;
  justify-content: center;
`;

const ContentRight = styled.div`
  display: flex;
  width: 50%;
  justify-content: flex-end;
`;

const Flex = styled.div`
  display: flex;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: right;
`;

const Row = styled.div<{
  underline?: boolean;
}>`
  line-height: 22px;
  display: flex;
  flex-direction: row;
  border-bottom: ${({ underline }) =>
    underline ? "1px solid lightgrey" : "none"};
  padding: 16px 0;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

const RowRightContent = styled(Row)`
  width: 100%;
  justify-content: flex-end;
`;

const buildLeftRightRow = (term: Array<string>, underline: boolean) => {
  return (
    <Row key={term[0]} underline={underline}>
      <ContentLeft>
        <Typography color={tokens.BRAND_HERO_BLUE}>{term[0]}</Typography>
      </ContentLeft>
      <ContentRight>{term[2]}</ContentRight>
    </Row>
  );
};

const buildLeftMiddleRightRow = (term: Array<string>, underline: boolean) => {
  return (
    <Row key={term[0]} underline={underline}>
      <CenteredOneThirdColumn>
        <Typography color={tokens.BRAND_HERO_BLUE}>{term[1]}</Typography>
      </CenteredOneThirdColumn>
      <CenteredOneThirdColumn>
        <Typography
          color={tokens.BRAND_HERO_BLUE}
          size={15}
          fontWeight={"regular"}
        >
          {term[0]}
        </Typography>
      </CenteredOneThirdColumn>
      <CenteredOneThirdColumn>{term[2]}</CenteredOneThirdColumn>
    </Row>
  );
};

const buildLeftRightColumnRow = (term: string[], underline: boolean) => {
  return (
    <Row key={term[0]} underline={underline}>
      <ContentLeft>
        <Typography>{term[0]}</Typography>
      </ContentLeft>
      <ContentRight>
        <Column>
          <Typography style={{ textAlign: "end" }}>{term[3]}</Typography>
          <Typography size={12} color={tokens.TINTS_OFF_WHITE_20}>
            {term[4]}
          </Typography>
        </Column>
      </ContentRight>
    </Row>
  );
};

const CenteredColumn = styled(Column)`
  justify-content: center;
  text-align: center;

  & > p {
    text-align: center;
  }
`;

const buildLeftcolMiddleRightcolRow = (term: string[], underline: boolean) => {
  return (
    <Row key={term[0]} underline={underline}>
      <CenteredOneThirdColumn>
        <CenteredColumn>
          <Typography color={tokens.BRAND_HERO_BLUE}>{term[1]}</Typography>
          <Typography
            fontWeight={"regular"}
            size={12}
            color={tokens.TINTS_OFF_WHITE_20}
          >
            {term[2]}
          </Typography>
        </CenteredColumn>
      </CenteredOneThirdColumn>
      <CenteredOneThirdColumn>
        {" "}
        <Typography
          color={tokens.BRAND_HERO_BLUE}
          size={15}
          fontWeight={"regular"}
        >
          {term[0]}
        </Typography>
      </CenteredOneThirdColumn>
      <CenteredOneThirdColumn>
        <CenteredColumn>
          <Typography color={tokens.BRAND_HERO_BLUE}>{term[3]}</Typography>
          <Typography
            fontWeight={"regular"}
            size={12}
            color={tokens.TINTS_OFF_WHITE_20}
          >
            {term[4]}
          </Typography>
        </CenteredColumn>
      </CenteredOneThirdColumn>
    </Row>
  );
};

export {
  Flex,
  Column,
  ContentRight,
  ContentLeft,
  Row,
  RowRightContent,
  CenteredOneThirdColumn,
  buildLeftRightColumnRow,
  buildLeftMiddleRightRow,
  buildLeftcolMiddleRightcolRow,
  buildLeftRightRow,
};

import React, { ReactNode, useEffect } from "react";
import { EmblaOptionsType } from "embla-carousel";
import styled from "@emotion/styled/macro";
import useEmblaCarousel from "embla-carousel-react";
import {
  DotButton,
  EmblaContainer,
  EmblaDots,
  EmblaSlide,
  EmblaViewport,
  NextButton,
  PrevButton,
  useDotButton,
  usePrevNextButtons,
} from "../atoms/emblaAtoms";
import { ContentLeft, ContentRight, Row } from "../atoms/layoutAtoms";

type PropType = {
  slides: ReactNode[];
  options?: EmblaOptionsType;
  showDots?: boolean;
  requestedIndex: number;
};

const FullSizeCarousel: React.FC<PropType> = (props) => {
  const { slides, requestedIndex, options } = props;
  const [ref, api] = useEmblaCarousel(options);

  useEffect(() => {
    if (requestedIndex > -1) {
      api?.scrollTo(requestedIndex);
    }
  }, [api, requestedIndex]);

  const { selectedIndex, scrollSnaps, onDotButtonClick } = useDotButton(api);

  const {
    prevBtnDisabled,
    nextBtnDisabled,
    onPrevButtonClick,
    onNextButtonClick,
  } = usePrevNextButtons(api);

  return (
    <>
      {slides.length > 1 && (
        <NoPaddingRow>
          <ContentLeft>
            <PrevButton
              onClick={onPrevButtonClick}
              disabled={prevBtnDisabled}
            />
          </ContentLeft>
          <ContentRight>
            <NextButton
              onClick={onNextButtonClick}
              disabled={nextBtnDisabled}
            />
          </ContentRight>
        </NoPaddingRow>
      )}
      <FSEmbla>
        <FSEmblaViewport ref={ref}>
          <FSEmblaContainer>
            {slides.map((child, index) => (
              <EmblaSlide key={index}>{child}</EmblaSlide>
            ))}
          </FSEmblaContainer>
        </FSEmblaViewport>
        <EmblaDots>
          {scrollSnaps.map((_, index) => (
            <DotButton
              key={index}
              onClick={() => onDotButtonClick(index)}
              className={"embla__dot".concat(
                index === selectedIndex
                  ? " embla__dot--selected full-size"
                  : " full-size"
              )}
            />
          ))}
        </EmblaDots>
      </FSEmbla>
    </>
  );
};

const FSEmbla = styled.section`
  --slide-height: calc(61vh);
  --slide-spacing: calc(10vw);
  --slide-size: calc(85vw);
  --slide-spacing-sm: calc(10vw);
  --slide-size-sm: 100vw;
  --slide-spacing-lg: 10vw;
  --slide-size-lg: 90vw;
`;

const FSEmblaContainer = styled(EmblaContainer)`
  margin-left: unset;
`;

const FSEmblaViewport = styled(EmblaViewport)`
  padding-bottom: 12px;
`;

const NoPaddingRow = styled(Row)`
  padding: 0;

  & > div > button.embla__button--prev {
    margin-top: 25%;
  }

  & > div > button.embla__button--next {
    margin-top: 25%;
  }
`;

export { FullSizeCarousel };

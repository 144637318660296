import React, { ReactNode } from "react";
import { EmblaOptionsType } from "embla-carousel";
import useEmblaCarousel from "embla-carousel-react";
import styled from "@emotion/styled/macro";
import {
  DotButton,
  EmblaContainer,
  NextButton,
  PrevButton,
  useDotButton,
  usePrevNextButtons,
} from "../atoms/emblaAtoms";
import { ContentLeft, ContentRight, Row } from "../atoms/layoutAtoms";

type PropType = {
  slides: ReactNode[];
  options?: EmblaOptionsType;
  showDots?: boolean;
};

const SimpleCarousel: React.FC<PropType> = (props) => {
  const { slides, options, showDots } = props;
  const [emblaRef, emblaApi] = useEmblaCarousel(options);

  const { selectedIndex, scrollSnaps, onDotButtonClick } =
    useDotButton(emblaApi);

  const {
    prevBtnDisabled,
    nextBtnDisabled,
    onPrevButtonClick,
    onNextButtonClick,
  } = usePrevNextButtons(emblaApi);

  return (
    <Embla>
      {slides && slides.length > 3 && (
        <Row>
          <ContentLeft>
            <PrevButton
              onClick={onPrevButtonClick}
              disabled={prevBtnDisabled}
            />
          </ContentLeft>
          <ContentRight>
            <NextButton
              onClick={onNextButtonClick}
              disabled={nextBtnDisabled}
            />
          </ContentRight>
        </Row>
      )}

      <EmblaViewport ref={emblaRef}>
        <EmblaContainer style={{ gap: 14 }}>
          {slides.map((child, index) => (
            <EmblaSlide key={index}>{child}</EmblaSlide>
          ))}
        </EmblaContainer>
      </EmblaViewport>

      {!!showDots && (
        <EmblaDots>
          {scrollSnaps.map((_, index) => (
            <DotButton
              key={index}
              onClick={() => onDotButtonClick(index)}
              className={"embla__dot".concat(
                index === selectedIndex ? " embla__dot--selected" : ""
              )}
            />
          ))}
        </EmblaDots>
      )}
    </Embla>
  );
};

const Embla = styled.section`
  --slide-height: 19rem;
  --slide-spacing: 12px;
  --slide-size: calc(97% / 3);
  --slide-spacing-sm: 1.6rem;
  --slide-size-sm: 100%;
  --slide-spacing-lg: 12px;
  --slide-size-lg: calc(100% / 3);
  margin-top: 16px;
`;
const EmblaViewport = styled.div`
  overflow: hidden;
  min-height: 200px;
`;

const EmblaSlide = styled.div`
  flex: 0 0 var(--slide-size);
  min-width: 0;
`;

const EmblaDots = styled.div`
  margin-top: 8px;
  gap: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export { SimpleCarousel };

import { useMediaQuery } from "react-responsive";
import { tokens, Typography } from "@sunrun/experience-ui-components";
import styled from "@emotion/styled/macro";
import {
  AgendaStep,
  SolarStepsContent,
} from "../../../components/molecules/SolarSteps";
import { OPTY_PROGRESS_STEPS } from "../../../checkout/util/constants";
import { useCapContext } from "../../providers/CapContext";

const NextSteps = () => {
  const { prospect } = useCapContext();
  const isMobile = useMediaQuery({ maxWidth: 1024 });

  const cxProName = prospect.projectManagerName
    ? `, ${prospect.projectManagerName} ,`
    : "";

  return (
    <NextStepsView>
      <Typography
        color={tokens.BRAND_HERO_BLUE}
        style={{ fontSize: 28, margin: 16 }}
      >
        Your CX Pro{cxProName} will reach out shortly to coordinate city
        permitting
      </Typography>
      <SolarStepsContent showSolarStepsDetails={true}>
        {OPTY_PROGRESS_STEPS.map((solarStep) => {
          return (
            <AgendaStep
              key={solarStep?.stepTitle}
              isMobile={isMobile}
              img={solarStep?.img}
              stepNumber={solarStep?.stepTitle}
              description={solarStep?.description}
              color={tokens.BRAND_MEDIUM_BLUE}
              completed={
                solarStep.stepTitle === "Step 1" ||
                solarStep.stepTitle === "Step 2"
              }
              current={solarStep.stepTitle === "Step 3"}
            />
          );
        })}
      </SolarStepsContent>
    </NextStepsView>
  );
};

const NextStepsView = styled.section`
  box-sizing: border-box;
`;

export { NextSteps };

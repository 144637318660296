import useSWR from "swr";
import { getWithAuth } from "../../utils/dataUtils";
import { Cap, CapDoc, Sow, UserProfile } from "../capTypes";

const useCap = (jwt: string, prospectId?: string) => {
  let url = `/prospect/${prospectId}/cap`;
  const shouldFetch = jwt && prospectId;
  const {
    data: cap,
    error: capError,
    isLoading: isCapLoading,
  } = useSWR(shouldFetch && [url, jwt], getWithAuth<Cap>);

  return { cap, capError, isCapLoading };
};

const useCapDocs = (jwt: string, prospectId?: string) => {
  let url = `/prospect/${prospectId}/getCapDocs`;
  const shouldFetch = jwt && prospectId;
  const {
    data: documents,
    error: documentsError,
    isLoading: isDocumentsLoading,
  } = useSWR(shouldFetch && [url, jwt], getWithAuth<CapDoc[]>);

  return { documents, documentsError, isDocumentsLoading };
};

const useSow = (jwt: string, prospectId?: string) => {
  let url = `/prospect/${prospectId}/getSow`;
  let sow: undefined | Sow, sowError: any, isSowLoading: boolean;
  ({
    data: sow,
    error: sowError,
    isLoading: isSowLoading,
  } = useSWR(prospectId && [url, jwt], getWithAuth<Sow>));

  return { sow, sowError, isSowLoading };
};

const useUserProfile = (jwt: string) => {
  let url = `/getUserProfile`;
  const {
    data: user,
    error: userError,
    isLoading: userLoading,
  } = useSWR([url, jwt], getWithAuth<UserProfile>);

  return { user, userError, userLoading };
};

export { useUserProfile, useCap, useCapDocs, useSow };

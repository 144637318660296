import React, { useEffect, useMemo, useState } from "react";
import styled from "@emotion/styled/macro";
import {
  Button,
  LoadingIndicator,
  Modal,
  SvgIcon,
  SvgNames,
  Typography,
  tokens,
} from "@sunrun/experience-ui-components";
import { Card } from "../molecules/Card";
import { ScheduleAppointment } from "./ScheduleAppointment";
import { useCheckoutContext } from "checkout/providers/CheckoutContext";
import { useSiteSurveyAppointments } from "checkout/api/siteSurvey";
import { useHqsEligibilityByProposalId } from "hooks/useHqs";
import { useHqsUrl } from "hooks/useHqs";
import { MOBILE_BREAKPOINT } from "hooks/useResponsive";

const HQS_COMPLETE_DESCRIPTION =
  "Your Home Qualification Survey is complete. We will review your responses and notify you by email.";
const HQS_COMPLETE_TITLE = "Virtual Home Review Completed";
const HQS_DESCRIPTION =
  "As a Sales Rep, you can complete your Home Qualification Survey now to speed the customer's timeline to installation.";
const HQS_SUBTITLE =
  "We’ll schedule an in-person or virtual survey to measure your roof and see if your home is ready for solar. We'll need to access your attic and electrical panel to complete this part.";
const HQS_REQUIRED_SUBTITLE =
  "We need to review your home and check for certain requirements - like whether your roof is in good condition or if we need to make electrical updates - to confirm that your house is ready for solar panels. In case you need help from a technician you can schedule a site visit.";
const HQS_TITLE = "Complete Home Review Now";
const HQS_REQUIRED_TITLE = "Complete Home Review";
const SCHEDULE_DESCRIPTION =
  "A Site Technician will visit your home to run an on-site qualification.";
const SCHEDULE_TITLE = "Schedule a visit with a technician";

const HqsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  > .card-container {
    display: flex;
    gap: 24px;
    max-width: 60em;
    @media screen and (max-width: ${MOBILE_BREAKPOINT}px) {
      flex-direction: column;
    }
    & > * {
      flex: 1 1 0px;
    }
  }
`;

const HqsCard = styled(Card)`
  display: flex;
  flex-direction: column;
  gap: 24px;
  justify-content: space-between;
  padding: 24px;
  & > .info {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
`;

const LoadingWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  min-height: 400px;
  font-size: ${tokens.FONT_SIZE_3}px;
  line-height: ${tokens.FONT_SIZE_4}px;
  color: ${tokens.BRAND_HERO_BLUE};
  letter-spacing: -0.4px;
  font-weight: 400;

  svg {
    /* Need !important to override inline styles */
    height: 40px !important;
    width: 40px !important;
  }
`;

const Disclaimer = styled.p`
  text-align: center;
  font-weight: 400;
  font-size: ${tokens.FONT_SIZE_0}px;
  color: ${tokens.TINTS_OFF_WHITE_20};
  margin-top: 12px;
`;

const HqsHelpButton = styled(Button)`
  width: fit-content;
  padding: 0;
`;

const HqsHelpLink = styled.a`
  color: ${tokens.BRAND_HERO_BLUE};
  text-decoration: underline;
  font-size: ${tokens.FONT_SIZE_1}px;
  font-weight: 500;
`;

const HqsHelpText = styled(Typography)`
  display: flex;
  flex-direction: column;
  gap: 10px;
  max-width: 640px;
`;

const SiteSurveyTaskView: React.FC = () => {
  const [showHqsHelp, setShowHqsHelp] = useState<boolean>(false);
  const [showAppointmentScheduler, setShowAppointmentScheduler] =
    useState<boolean>(false);
  const [showErrorModal, setShowErrorModal] = useState<boolean>(false);

  const {
    prospectId,
    proposalId,
    currentTask,
    setLoadingOverride,
    handleTitleOverride,
    isHqsRequired,
  } = useCheckoutContext();

  const {
    data: isEligibleForHqsData,
    loading: isEligibleForHqsLoading,
    error: hqsError,
    mutate: hqsRetry,
  } = useHqsEligibilityByProposalId(proposalId);
  const {
    siteSurveyAppointment: isAppointmentScheduled,
    siteSurveyAppointmentsLoading: isAppointmentScheduledLoading,
  } = useSiteSurveyAppointments(prospectId);
  const hqsUrl = useHqsUrl({ prospectId });

  const homeReviewLoading =
    isEligibleForHqsLoading || isAppointmentScheduledLoading;

  useEffect(() => {
    setLoadingOverride(homeReviewLoading && !showAppointmentScheduler);
  }, [homeReviewLoading, setLoadingOverride, showAppointmentScheduler]);

  useEffect(() => {
    if (!!hqsError !== showErrorModal) {
      setShowErrorModal(!!hqsError);
    }
  }, [hqsError, showErrorModal]);

  useEffect(() => {
    let title = undefined;

    if (isHqsRequired) {
      title = "Home Review";
    }

    if (!isEligibleForHqsData || showAppointmentScheduler) {
      title = "Schedule On-Site Home Review";
    }

    handleTitleOverride(title);
  }, [
    handleTitleOverride,
    isEligibleForHqsData,
    isHqsRequired,
    showAppointmentScheduler,
  ]);

  const HqsTask: React.FC = useMemo(
    () => () => {
      return (
        <HqsContainer>
          <Typography>
            {isHqsRequired ? HQS_REQUIRED_SUBTITLE : HQS_SUBTITLE}
          </Typography>
          <div className="card-container">
            <HqsCard>
              {!currentTask?.isComplete && (
                <>
                  <div className="info">
                    <SvgIcon name={SvgNames.Clipboard} width={56} height={56} />
                    <Typography size="large" fontWeight="medium">
                      {isHqsRequired ? HQS_REQUIRED_TITLE : HQS_TITLE}
                    </Typography>
                    <Typography>{HQS_DESCRIPTION}</Typography>
                  </div>
                  <div className="actions">
                    <Button
                      data-testid="site-survey-hqs-open"
                      size="sm"
                      color={isHqsRequired ? "primary" : "secondary"}
                      onClick={() => window.open(hqsUrl, "_blank")}
                    >
                      {isHqsRequired ? "Start Home Review" : "Start"}
                    </Button>
                  </div>
                </>
              )}
              {currentTask?.isComplete && (
                <div className="info" data-testid="site-survey-hqs-complete">
                  <SvgIcon
                    name={SvgNames.CheckEnclosed}
                    width={56}
                    height={56}
                    color={tokens.SUCCESS_50}
                  />
                  <Typography size="large" fontWeight="medium">
                    {HQS_COMPLETE_TITLE}
                  </Typography>
                  <Typography>{HQS_COMPLETE_DESCRIPTION}</Typography>
                </div>
              )}
            </HqsCard>
            {!isHqsRequired && (
              <HqsCard>
                <div className="info">
                  <SvgIcon name={SvgNames.HardHat} height={56} />
                  <Typography size="large" fontWeight="medium">
                    {SCHEDULE_TITLE}
                  </Typography>
                  <Typography>{SCHEDULE_DESCRIPTION}</Typography>
                </div>
                <div className="actions">
                  <Button
                    data-testid="site-survey-schedule-appointment"
                    size="sm"
                    color="secondary"
                    onClick={() => setShowAppointmentScheduler(true)}
                  >
                    Schedule
                  </Button>
                </div>
              </HqsCard>
            )}
          </div>
          {isHqsRequired && (
            <>
              <HqsHelpButton
                onClick={() => setShowHqsHelp((prev) => !prev)}
                color="tertiary"
                data-testid="home-review-troubleshoot"
                iconRight={
                  <SvgIcon
                    name={
                      showHqsHelp ? SvgNames.ChevronDown : SvgNames.ChevronRight
                    }
                    color={tokens.BLACK}
                    style={{ width: "16px", height: "16px" }}
                  />
                }
              >
                Are you having trouble completing the home review?
              </HqsHelpButton>
              {showHqsHelp && (
                <HqsHelpText color={tokens.TINTS_OFF_WHITE_20}>
                  <span>
                    If your HQS submission is rejected, you will automatically
                    receive a link to schedule a technician site survey via text
                    and email.
                  </span>
                  <span>
                    If you (1) don’t receive the self-scheduling link, (2) are
                    having issues scheduling the appointment or (3) this is a
                    remote sale, please call Sunrun Sales Support{" "}
                    <HqsHelpLink href="tel:888-840-7180">
                      888-840-7180
                    </HqsHelpLink>
                  </span>
                </HqsHelpText>
              )}
            </>
          )}
          {showErrorModal && (
            <Modal
              hideClose
              type="error"
              title="There was an issue verifying your HQS eligibility"
              primaryButton={{
                testId: "modal-home-review-retry",
                text: "Retry",
                onClick: hqsRetry,
              }}
              secondaryButton={{
                testId: "modal-home-review-schedule-appointment",
                text: "Schedule a visit",
                onClick: () => {
                  setShowErrorModal(false);
                  setShowAppointmentScheduler(true);
                },
              }}
              onClose={() => {
                setShowErrorModal(false);
                setShowAppointmentScheduler(true);
              }}
            >
              <Typography>
                Something went wrong verifying your eligibility for Home
                Qualification Survey. Please retry, or schedule an on-site visit
                with a technician.
              </Typography>
            </Modal>
          )}
        </HqsContainer>
      );
    },
    [
      currentTask?.isComplete,
      hqsRetry,
      hqsUrl,
      isHqsRequired,
      showErrorModal,
      showHqsHelp,
    ]
  );

  if (showAppointmentScheduler) {
    return <ScheduleAppointment />;
  }
  if (
    currentTask?.isComplete &&
    !isAppointmentScheduledLoading &&
    isAppointmentScheduled
  ) {
    return <ScheduleAppointment />;
  }

  if (homeReviewLoading) {
    return (
      <LoadingWrapper>
        <LoadingIndicator color="black" message="Loading Home Review" />
        <Disclaimer>It may take several minutes to update.</Disclaimer>
      </LoadingWrapper>
    );
  }
  return (
    <>
      {(isEligibleForHqsData || showErrorModal) && <HqsTask />}
      {!isEligibleForHqsData && !showErrorModal && <ScheduleAppointment />}
    </>
  );
};

export {
  HQS_COMPLETE_DESCRIPTION,
  HQS_COMPLETE_TITLE,
  HQS_DESCRIPTION,
  HQS_SUBTITLE,
  HQS_TITLE,
  SCHEDULE_DESCRIPTION,
  SCHEDULE_TITLE,
  SiteSurveyTaskView,
};

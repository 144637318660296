import {
  AWAITING_AUDIT,
  AWAITING_DESIGN,
  AWAITING_PC,
  CLOSED,
  INACTIVE,
  OPEN,
} from "./constants/capConstants";

type CapDoc = {
  isSOWDocument: boolean;
  isProposalDocument: boolean;
  docusignStatus: "Sent" | "Unsent" | null;
  documentType: string;
  documentName: string;
  documentLink: string;
  documentId: string;
  customerSigned: "Sent" | "Customer Signed";
};

type StepName =
  | "Design"
  | "Equipment location"
  | "Home readiness"
  | "Read & sign"
  | "Next steps";

type WorkType =
  | "Electrical"
  | "Roofing"
  | "Structural"
  | "Misc"
  | "Post Install Repair";

type DocumentEmail = {
  primaryContactId: string;
  customerSigned: "Not Initiated" | "Sent" | "Customer Signed";
  documentType: string;
  srAttachmentId: string;
};

type LineItem = {
  sow_id: string;
  line_item_id: string;
  line_item_name: string;
  line_item_type: WorkType;
  line_item_subType: string;
  line_item_workOrderId: string;
  line_item_price: number;
  line_item_pricingType: string;
  line_item_isEligibleForItc: string; // "Yes" or "No"
  line_item_assignment: string;
  line_item_installSequencing: string;
  line_item_description: string;
  line_item_instructions: string;
};

type WorkItem = {
  type: WorkType;
  price: number;
  images: [string];
  lineItems: [LineItem];
};

type Proposal = {
  lastModifiedBy: string;
  lastModifiedDate: string;
  createdBy: string;
  createdDate: string;
  achFeeEligible: string;
  actualEscalator: number;
  agreementType: string;
  allElectric: boolean;
  annualElectricUsage: number;
  annualPreSolarBill: number;
  annualPreSolarKwhUsage: number;
  aprKwh: number;
  aprUsage: number;
  assetInfo?: {
    category: string;
    count: number;
    id: string;
    isFullProposal: boolean;
    manufacturer: string;
    model: string;
    name: string;
    proposalName: string;
    sku: string;
    totalEstGaapCost: number;
    totalPrice: number;
    totalTax: number;
    type: string;
    usefulLife: number;
  }[];
  augKwh: number;
  augUsage: number;
  AvailableCommission: number;
  avgMonthlyElectricityUsage: number;
  avoidedCostOfPower: number;
  billingMethod: string;
  billingType: string;
  brightBox: boolean;
  buyoutPerWatt: number;
  changeOrder: boolean;
  changeOrderInformation: string;
  collectRebate: boolean;
  costStack: string;
  customerAddress: string;
  customerEmail: string;
  customerName: string;
  customerOriginationFeePart2: number;
  customerOriginationFeePerWatt: number;
  customerPhone: string;
  customerPreSolarBill: number;
  customerSelectedSizing: number;
  dealType: string;
  decKwh: number;
  decUsage: number;
  deposit: number;
  equipmentSummary: string;
  escalationRate: number;
  Estimatedcommission: number;
  epcPrice: number;
  epcPricePerWatt: number;
  estimatedGaapCost: number;
  estimatedYear1Generation: number;
  estimationMethod: string;
  externalId: string;
  febKwh: number;
  febUsage: number;
  financingTermLength: string;
  firstYearGenerationEstimate: number;
  giftCardAmount: number;
  grossSystemPurchasePrice: number;
  guaranteedProductionToDate: string;
  highestRoofStory: number;
  homeType: string;
  id: string;
  indexedContractValue: number;
  installationPrice: number;
  installPartner: string;
  issuedDateTime: string;
  janKwh: number;
  janUsage: number;
  julKwh: number;
  julUsage: number;
  junKwh: number;
  junUsage: number;
  lengthOfAgreement: number;
  livingSpaceSquareFootage: number;
  loanAnnualPercentageRate: number;
  localRebate: number;
  manualShadingUsed: boolean;
  marKwh: number;
  marUsage: number;
  mayKwh: number;
  mayUsage: number;
  minimumCashPurchasePrice: string;
  modulesPrice: number;
  monthlySolarProdRatio: string;
  monthlySolarProduction: string;
  monthlySunrunBill: number;
  novKwh: number;
  novUsage: number;
  numberOfArrays: number;
  numberOfInverters: number;
  octKwh: number;
  octUsage: number;
  opportunityId: string;
  parentProposalId: string;
  performanceGuaranteeCommenceYear: number;
  powerElectronicPrice: number;
  prepayAmount: number;
  prepayBalanceLeaseTerm: string;
  prepayDiscountRate: number;
  prepayPaymentForElectricity: number;
  pricePerKwh: number;
  priceWattForPricing: number;
  productionEstimateSource: string;
  productionEstimateSourceAdjustRatio: number;
  productOffering: string;
  ProductPackage: string;
  productType: string;
  proposalId: string;
  proposalName: string;
  proposalOrigin: string;
  proposalScenarios: string;
  proposalSource: string;
  prospectId: string;
  rebateLevel: number;
  recPriceLevelUsedForPricing: number;
  regenerateDocsStatus: string;
  roofWarrantyLength: number;
  salesOffice: string;
  salesOriginationFeePriceLevel: string;
  salesPartner: string;
  salesRepEmail1: string;
  sepKwh: number;
  sepUsage: number;
  signed: boolean;
  srecLevelExpected: number;
  srecTerm: number;
  srPanelDerate: number;
  stage: string;
  sunrunDirectSalesFeePerW: number;
  sunrunRatePerkWh: number;
  systemGrossCost: number;
  systemSize: number;
  systemSizeCecAc: number;
  taxAmount: number;
  taxOnInstallation: number;
  territory: string;
  totalExpectedProductionAllArrays: number;
  totalNoPanels: number;
  totalProductionOverAgreementLength: number;
  totalRebateAmount: number;
  tsrf: number;
  turnkeyPurchasePrice: number;
  upfrontPayment: number;
  upfrontPaymentAmount: number;
  usageOffset: number;
  utility: string;
  utilityRateEscalator: number;
  x1stCustomerPaymentTerms: number;
  x2ndCustomerPaymentTerms: number;
  x3rdCustomerPaymentTerms: number;
  year1MaximumMonthlyBill?: number;
  year1MinimumMonthlyBill?: number;
  year1AnnualSavings: number;
  year1PostSolarMonthlyElectricBill: number;
  year1ProductionForPricing: number;
  yearlyRefundRate: string;
};

interface CapTask {
  id: string;
  name: string;
  phaseId: string;
  slaInHours: number;
  status: string;
  taskNumber: string;
  dueDate?: string;
  startDate?: string;
  hasCompleteCapTaskPendingSync: boolean;
}

interface CapNextStepTask {
  delayReason: string;
  dueDate: string;
  nextStep: string;
  setReminder: boolean;
}

interface CapRelatedTask {
  name: string;
  status: string;
  dueDate?: string;
}

interface CapDesignPreference {
  question: string;
  response: string;
}

type Sow = {
  allSharinPixImages: any[];
  proposalName: string;
  id: string;
  ownerId: string;
  name: string;
  projectId: string;
  prospectId: string;
  priceToCustomer: number;
  totalSubsidy: number;
  totalTax: number;
  grossPriceEligibleforItc: number;
  priceOfAllWork: number;
  designImage: string;
  designPlan: string; // "Yes" or "No"
  workItems: [WorkItem];
  proposal: any;
  year1SolarPricePerKwh: string; // this string has a numerical value as such : "0.023"
  originalYear1SolarPricePerKwh: string;
  lastCustomerSignedProposalId: string;
  billingMethod: string;
  paymentMode: string;
};

type Cap = {
  accountId: string;
  capTaskId?: string;
  capType?: CapType;
  capTypeLetter?: string;
  customerAddress?: string;
  customerEmail: string;
  customerName: string;
  customerPhone: string;
  designPreferences: Array<CapDesignPreference>;
  dueDate?: string;
  endDate?: string;
  hasCompleteCapTaskPendingSync: boolean;
  id: string;
  KEY_VAL?: string;
  nextStepTasks: Array<CapNextStepTask>;
  opportunityId?: string;
  opportunityName?: string;
  projectId: string;
  projectName: string;
  projectStatus: string;
  projectUrl?: string;
  proposalId: string;
  prospectId: string;
  relatedTasks: Array<CapRelatedTask>;
  salesCapTasks: Array<CapTask>;
  salesRepId?: string;
  slaInHours?: number;
  slaStatus?: "Red" | "Green";
  sowId?: string;
  sowMasteryId?: string;
  startDate?: string;
  status?: CapStatus;
  utility?: string;
  type?: CapType;
};

type CapType = "A" | "B" | "C" | "D";

type CapStatus =
  | typeof AWAITING_AUDIT
  | typeof AWAITING_DESIGN
  | typeof AWAITING_PC
  | typeof CLOSED
  | typeof INACTIVE
  | typeof OPEN;

type ViewType = "Single" | "Comparison";

type RowProps = {
  viewType: ViewType;
};

//TODO: Fix this in SPLAT1-7442 -- everything below this line should be imported from shared lib

type SplatUserType =
  | "Customer"
  | "Direct to Home"
  | "Field Sales"
  | "Fusion Closer"
  | "Fusion Setter"
  | "Inside Sales"
  | "Integrated Partner"
  | "Other"
  | "Retail Advisor"
  | "Sales-only Partner"
  | "Third Party Retail Advisor";

type UserProfile = {
  contactId: string;
  crmLoginBlocked: boolean;
  division: string;
  email: string;
  firstName: string;
  fullPhotoUrl: string;
  hasDoorsAccess: boolean;
  hasLeagueLiveAccess: boolean;
  hasSalesAssistAccess: boolean;
  hasSquarePhoto?: boolean;
  isBrightGridOperator: boolean;
  isDirectToHome: boolean;
  isFusion: boolean;
  isPartner: boolean;
  isSunrunSalesRep: boolean;
  jobFunction: string;
  lastName: string;
  partnerName: string;
  partnerType: string;
  pricingDivision: string;
  profilePhoto?: string;
  rank: string;
  salesTeam?: string;
  sellsSunrun: "Yes" | "No";
  sfdcContact?: UserProfile;
  splatRole: string;
  sunrunUser?: string;
  timezoneId: string;
  title: string;
  userId: string;
  username: string;
  userType?: "mysunrun";
};

type VivintEligibilityResponse = {
  id?: string;
  author?: string | null;
  prospectId?: string | null;
  eligibility: {
    isEligible: boolean;
    zipcodeIsEligble?: boolean;
    isCAPPrepared?: boolean;
    isVivintNewCustomerEligible?: boolean;
  } | null;
  customerId?: string | null;
  customerOffer?: unknown;
  lockVersion?: number;
  createdAt?: string;
  createdBy?: string;
  updatedAt?: string;
  updatedBy?: string;
};

/**
 * AKA: Integrated, NRG, Affiliate
 *
 * Third-party use of our sales tools.
 */
const CHANNEL_PARTNER = "Integrated Partner";
/**
 * AKA: MySunrun, MSR
 *
 * Someone buying.
 */
const CUSTOMER = "Customer";
/**
 * AKA: D2H
 *
 * Sunrun Managed Sales Rep that goes door-to-door creating leads and selling.
 * Part of the Street division
 */
const DIRECT_TO_HOME = "Direct to Home";
/**
 * AKA: Direct, DFS
 *
 * Sunrun Managed Sales Rep that can go to a house.
 */
const FIELD_SALES = "Field Sales";
/**
 * AKA: Expert
 *
 * Sunrun Managed lead generator that also sells.
 * Can continue a Fusion Setter's leads.
 * Part of the Street division
 */
const FUSION_CLOSER = "Fusion Closer";
/**
 * AKA: Athlete
 *
 * Sunrun managed lead generator for Fusion Closers.
 * Frequently a college student.
 * Part of the Street division
 */
const FUSION_SETTER = "Fusion Setter";
/**
 * AKA: NIS, AIR
 *
 * Blue Sunrun Sales Rep that only uses a phone.
 */
const INSIDE_SALES = "Inside Sales";
/**
 * We don't know.
 *
 * Many times, there is a User Profile configuration change in SFDC that causes this.
 */
const OTHER = "Other";
/**
 * AKA: Retail Sales Advisor, RSA, Clean Energy Advisor, CEA
 *
 * Creates leads in a Costco/Home Depot/etc.
 */
const RETAIL_ADVISOR = "Retail Advisor";
/**
 * AKA: Vivint SmartHome, VSH
 * Non-Sunrun creating leads.
 * They get commission at a proposal stage.
 * Can be removed at a later date.
 */
const SALES_ONLY_PARTNER = "Sales-only Partner";
/**
 * AKA: Third Party Retail Sales Advisor, TPRSA, Third Party Clean Energy Advisor, TPCEA
 *
 * Creates leads, but are contract workers. Uses Smart Circle to login.
 */
const THIRD_PARTY_RETAIL_ADVISOR = "Third Party Retail Advisor";

type SfdcEnv = "SFDC" | "Community" | "D2HCommunity" | "None";

const getSplatUserType = (tokenUserProfile: UserProfile): SplatUserType => {
  const userProfile = tokenUserProfile.sfdcContact ?? tokenUserProfile;
  if (userProfile.userType === "mysunrun") return CUSTOMER;
  if (userProfile.partnerName === "Direct to Home") return DIRECT_TO_HOME;
  if (userProfile.partnerName === "Fusion" && userProfile.sellsSunrun === "No")
    return FUSION_SETTER;
  if (userProfile.partnerName === "Fusion" && userProfile.sellsSunrun === "Yes")
    return FUSION_CLOSER;
  if (
    userProfile.partnerName &&
    userProfile.partnerName.includes("Retail Third Party")
  )
    return THIRD_PARTY_RETAIL_ADVISOR;
  if (userProfile.splatRole === "Field Sales") return FIELD_SALES;
  if (userProfile.splatRole === "Inside Sales") return INSIDE_SALES;
  if (userProfile.splatRole === "Retail Advisor") return RETAIL_ADVISOR;
  if (userProfile.splatRole === "Sales-only Partner") return SALES_ONLY_PARTNER;
  if (userProfile.splatRole === "Integrated Partner") return CHANNEL_PARTNER;
  /**
   * This is the default we choose for when a splatRole doesn't come back
   * If a user is reporting they are seeing things they shouldn't or should,
   * this is a good suspect.
   */
  return OTHER;
};

const isRetailUser = (userType: SplatUserType) =>
  userType === RETAIL_ADVISOR || userType === THIRD_PARTY_RETAIL_ADVISOR;

/**
 * AKA: SRM
 */
const SUNRUN_MANAGED: Array<SplatUserType> = [
  "Field Sales",
  "Inside Sales",
  "Fusion Closer",
  "Fusion Setter",
  "Direct to Home",
  "Retail Advisor",
];

const isSunrunManaged = (userProfile: UserProfile): boolean =>
  SUNRUN_MANAGED.includes(getSplatUserType(userProfile));

const canViewDesignPreferences = (userProfile: UserProfile): boolean =>
  SUNRUN_MANAGED.includes(getSplatUserType(userProfile));

const SIGN_IN_PERSON: Array<SplatUserType> = [
  "Customer",
  "Field Sales",
  "Integrated Partner",
  "Retail Advisor",
  "Sales-only Partner",
  "Third Party Retail Advisor",
];

const canSignInPerson = (userProfile: UserProfile): boolean =>
  SIGN_IN_PERSON.includes(getSplatUserType(userProfile));

const SFDC_ENV: Record<SplatUserType, SfdcEnv> = {
  [CHANNEL_PARTNER]: "Community",
  [CUSTOMER]: "None",
  [DIRECT_TO_HOME]: "D2HCommunity",
  [FIELD_SALES]: "SFDC",
  [FUSION_CLOSER]: "D2HCommunity",
  [FUSION_SETTER]: "D2HCommunity",
  [INSIDE_SALES]: "SFDC",
  [OTHER]: "None",
  [RETAIL_ADVISOR]: "SFDC",
  [SALES_ONLY_PARTNER]: "Community",
  [THIRD_PARTY_RETAIL_ADVISOR]: "None",
};

const getSfdcEnv = (userProfile: UserProfile): SfdcEnv =>
  SFDC_ENV[getSplatUserType(userProfile)] ?? "None";

type Environment = "production" | "staging" | "development" | "relcert";

/**
 * Gets the current environment:
 * - "production"
 * - "staging"
 * - "development"
 * - "relcert"
 *
 * Looks in these places in this order:
 * - process.env.ENV
 * - process.env.REACT_APP_ENV_NAME
 * - process.env.envName
 *
 * @example
 * ```ts
 * const environment = getEnvironment();
 * ```
 */
const getEnvironment = (): Environment => {
  const environment =
    process.env.ENV ?? process.env.REACT_APP_ENV_NAME ?? process.env.envName;
  if (!environment)
    throw new Error(
      "Missing ENV, REACT_APP_ENV_NAME, or envName on process.env!"
    );
  switch (environment) {
    case "prd":
    case "production":
      return "production";
    case "development":
    case "devmaj":
      return "development";
    case "relcert":
      return "relcert";
    case "majstg":
    case "staging":
    default:
      return "staging";
  }
};

export {
  getEnvironment,
  canViewDesignPreferences,
  getSplatUserType,
  isSunrunManaged,
  isRetailUser,
  getSfdcEnv,
  canSignInPerson,
  type Environment,
  type SfdcEnv,
  type SplatUserType,
  type UserProfile,
  type VivintEligibilityResponse,
  CHANNEL_PARTNER,
  CUSTOMER,
  DIRECT_TO_HOME,
  FIELD_SALES,
  FUSION_CLOSER,
  FUSION_SETTER,
  INSIDE_SALES,
  OTHER,
  RETAIL_ADVISOR,
  SALES_ONLY_PARTNER,
  THIRD_PARTY_RETAIL_ADVISOR,
  type CapDoc,
  type DocumentEmail,
  type StepName,
  type Sow,
  type WorkItem,
  type LineItem,
  type Proposal,
  type Cap,
  type CapType,
  type ViewType,
  type RowProps,
  type WorkType,
};

import { API } from "aws-amplify";
import { VivintEligibilityResponse } from "../cap/capTypes";

const performRequest = async <Response>(
  method: "get" | "post" | "del" | "patch",
  jwt: string,
  path: string,
  body?: any,
  headers?: Record<string, unknown>
): Promise<Response> => {
  const response = await API[method]("OfferExpApi", path, {
    headers: {
      Authorization: `Bearer ${jwt}`,
      ...headers,
    },
    body,
  });
  return response as Response;
};

const checkVSHEligibility = async (
  jwt: string,
  prospectId: string,
  author?: string | null
): Promise<VivintEligibilityResponse> => {
  const url = `/prospect/${prospectId}/vsh`;
  return await performRequest("post", jwt, url, {
    author: author ?? null,
  });
};
export { checkVSHEligibility };

import React from "react";
import { formatCurrency, formatPercent } from "../../../utils/formatHelpers";
import {
  buildLeftMiddleRightRow,
  buildLeftRightRow,
  Row,
} from "../atoms/layoutAtoms";
import { useCapContext } from "../../providers/CapContext";
import { RowProps } from "../../capTypes";

const FinancialTermsRows = ({ viewType }: RowProps) => {
  const { sow, originalProposal, activeProposal } = useCapContext();

  let rows = [];
  const dealType = activeProposal?.dealType;

  switch (dealType) {
    case "Cash":
    case "Prepaid":
    case "Customer Owned":
    case "Customer Owned - Bank Financed":
      const cashData = [
        [
          "Deposit",
          `${formatCurrency(originalProposal?.deposit) ?? null}`,
          `${formatCurrency(activeProposal?.deposit) ?? null}`,
        ],
        [
          "Final Payment",
          `${formatCurrency(originalProposal?.upfrontPaymentAmount) ?? null}`,
          `${formatCurrency(activeProposal?.upfrontPaymentAmount) ?? null}`,
        ],
      ];
      if (viewType === "Single") {
        rows = cashData.map((term) => {
          return buildLeftRightRow(term, term[0] !== "Final Payment");
        });
      } else {
        rows = cashData.map((term) => {
          return buildLeftMiddleRightRow(term, term[0] !== "Final Payment");
        });
      }
      break;
    case "Loan":
      const loanData = [
        [
          "Deposit",
          `${formatCurrency(originalProposal?.deposit ?? 0)}`,
          `${formatCurrency(activeProposal?.deposit)}`,
        ],
        [
          "APR",
          `${originalProposal?.loanAnnualPercentageRate}%`,
          `${activeProposal?.loanAnnualPercentageRate}%`,
        ],
        [
          "Monthly Payment",
          `${formatCurrency(originalProposal?.monthlySunrunBill)}`,
          `${formatCurrency(activeProposal?.monthlySunrunBill)}`,
        ],
      ];
      if (viewType === "Single") {
        rows = loanData.map((term) => {
          return buildLeftRightRow(term, term[0] !== "Monthly Payment");
        });
      } else {
        rows = loanData.map((term) => {
          return buildLeftMiddleRightRow(term, term[0] !== "Monthly Payment");
        });
      }
      break;
    case "FlexMonthly":
      const flexData = [
        [
          "Min. Monthly Payment in Year 1",
          formatCurrency(originalProposal?.year1MinimumMonthlyBill) ?? "",
          formatCurrency(activeProposal?.year1MinimumMonthlyBill) ?? "",
        ],
        [
          "Max Monthly Payment in Year 1",
          formatCurrency(originalProposal?.year1MaximumMonthlyBill) ?? "",
          formatCurrency(activeProposal?.year1MaximumMonthlyBill) ?? "",
        ],
        [
          "Annual Percentage Increase",
          `${formatPercent(originalProposal?.escalationRate ?? 0)}`,
          `${formatPercent(activeProposal?.escalationRate ?? 0)}`,
        ],
        [
          "Flex Rate per kWh, Year 1",
          `${
            sow?.originalYear1SolarPricePerKwh
              ? formatCurrency(
                  parseFloat(sow?.originalYear1SolarPricePerKwh) ?? 0,
                  3
                )
              : "N/A"
          }`,
          `${
            sow?.year1SolarPricePerKwh
              ? formatCurrency(parseFloat(sow?.year1SolarPricePerKwh) ?? 0, 3)
              : "N/A"
          }`,
        ],
      ];

      if (viewType === "Single") {
        rows = flexData.map((term) => {
          return buildLeftRightRow(
            term,
            term[0] !== "Flex Rate per kWh, Year 1"
          );
        });
      } else {
        rows = flexData.map((term) => {
          return buildLeftMiddleRightRow(
            term,
            term[0] !== "Flex Rate per kWh, Year 1"
          );
        });
      }
      break;
    case "Monthly":
    case "monthly":
      const monthlyData = [
        [
          "Monthly Payment in Year 1",
          `${formatCurrency(originalProposal?.monthlySunrunBill)}`,
          `${formatCurrency(activeProposal?.monthlySunrunBill)}`,
        ],
        [
          "Annual Percentage Increase",
          `${formatPercent(originalProposal?.escalationRate ?? 0)}`,
          `${formatPercent(activeProposal?.escalationRate ?? 0)}`,
        ],
        [
          "Solar Cost per kWh in Year 1",
          `${
            sow?.originalYear1SolarPricePerKwh
              ? `${formatCurrency(
                  parseFloat(sow?.originalYear1SolarPricePerKwh) ?? 0,
                  3
                )} kWh DC`
              : "N/A"
          }`,
          `${
            sow?.year1SolarPricePerKwh
              ? `${formatCurrency(
                  parseFloat(sow?.year1SolarPricePerKwh) ?? 0,
                  3
                )} kWh DC`
              : "N/A"
          }`,
        ],
      ];
      if (viewType === "Single") {
        rows = monthlyData.map((term) => {
          return buildLeftRightRow(
            term,
            term[0] !== "Solar Cost per kWh in Year 1"
          );
        });
      } else {
        rows = monthlyData.map((term) => {
          return buildLeftMiddleRightRow(
            term,
            term[0] !== "Solar Cost per kWh in Year 1"
          );
        });
      }

      break;
    default:
      return <Row></Row>;
  }
  return <>{rows.map((row) => row)}</>;
};

export { FinancialTermsRows };

import styled from "@emotion/styled/macro";
import { zodResolver } from "@hookform/resolvers/zod";
import { Button, TextField, tokens } from "@sunrun/experience-ui-components";
import { Controller, useForm } from "react-hook-form";
import MaskedInput from "react-input-mask";

import { maxWidth } from "components/atoms/GlobalStyles";
import { contactFormSchema } from "providers/schema";
import { SplatContact } from "providers/Types";

const ContactForm = ({
  disabled = false,
  hideCancelButton = false,
  contact,
  onSubmit,
  onCancel,
  disableFields = [],
  itemsPerRow = 2,
}: ContactFormProps) => {
  const editMode = !!contact?.contactId;

  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: zodResolver(contactFormSchema),
    ...(editMode && {
      defaultValues: {
        ...contact,
      },
    }),
  });

  return (
    <Form $itemsPerRow={itemsPerRow}>
      <TextField
        disabled={editMode && disableFields.includes("customerFirstName")}
        label="*First Name"
        placeholder="First Name"
        isError={!!errors?.customerFirstName}
        helperText={errors?.customerFirstName?.message}
        {...register("customerFirstName")}
      />
      <TextField
        disabled={editMode && disableFields.includes("customerLastName")}
        label="*Last Name"
        placeholder="Last Name"
        isError={!!errors?.customerLastName}
        helperText={errors?.customerLastName?.message}
        {...register("customerLastName")}
      />
      <Divider $itemsPerRow={itemsPerRow} />
      <Controller
        name="customerPrimaryPhone"
        control={control}
        render={({ field }) => {
          return (
            <MaskedInput
              mask="(999) 999-9999"
              disabled={
                editMode && disableFields.includes("customerPrimaryPhone")
              }
              {...field}
            >
              {/*@ts-ignore*/}
              <TextField
                label="*Phone Number"
                isError={!!errors?.customerPrimaryPhone}
                helperText={errors?.customerPrimaryPhone?.message}
                type="tel"
                placeholder="(000)-000-0000"
              />
            </MaskedInput>
          );
        }}
      />
      <TextField
        disabled={editMode && disableFields.includes("customerEmail")}
        label="*Email"
        placeholder="Email"
        isError={!!errors?.customerEmail}
        helperText={errors?.customerEmail?.message}
        {...register("customerEmail")}
      />
      <ActionWrapper $itemsPerRow={itemsPerRow}>
        {!hideCancelButton && (
          <Button
            color="secondary"
            onClick={(e) => {
              e.preventDefault();
              onCancel();
            }}
            disabled={disabled}
            size="sm"
          >
            Cancel
          </Button>
        )}
        <Button
          size="sm"
          disabled={disabled}
          onClick={(e) => {
            e.preventDefault(); // stop page from reloading
            return handleSubmit((splatContact) =>
              onSubmit({
                ...splatContact,
                ...(contact?.contactId && { contactId: contact.contactId }),
              })
            )();
          }}
        >
          Save
        </Button>
      </ActionWrapper>
    </Form>
  );
};

const Form = styled.form<{ $itemsPerRow: number }>`
  display: grid;
  gap: 26px 16px;
  grid-template-columns: repeat(${({ $itemsPerRow }) => $itemsPerRow}, 1fr);

  @media (max-width: ${maxWidth}px) {
    grid-template-columns: 1fr;
  }
`;

const Divider = styled.div<{ $itemsPerRow: number }>`
  height: 1px;
  background-color: ${tokens.BORDER_LIGHT};
  grid-column: span ${({ $itemsPerRow }) => $itemsPerRow};

  @media (max-width: ${maxWidth}px) {
    grid-column: span 1;
  }
`;

const ActionWrapper = styled.div<{ $itemsPerRow: number }>`
  display: flex;
  gap: 16px;
  justify-content: end;
  grid-column: span ${({ $itemsPerRow }) => $itemsPerRow};

  > div {
    width: 147px;
  }

  @media (max-width: ${maxWidth}px) {
    grid-column: span 1;
    justify-content: center;
  }
`;

export type ContactFormProps = {
  disabled?: boolean;
  hideCancelButton?: boolean;
  contact?: SplatContact;
  onSubmit: (form: SplatContact) => void;
  onCancel: () => void;
  disableFields?: Array<string>;
  itemsPerRow?: number;
};

export { ContactForm };

import { createContext, useContext, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { rollbar } from "../../providers/rollbar";
import { useAppSelector } from "../../store";
import { useCap, useCapDocs, useSow, useUserProfile } from "../hooks/useCapSwr";
import {
  Cap,
  CapDoc,
  CapType,
  getSfdcEnv,
  Proposal,
  SfdcEnv,
  Sow,
} from "../capTypes";
import {
  SplatContact,
  SplatProposal,
} from "../../../amplify/backend/function/OfferExpContacts/ts/public/types";
import { useContactsData, useProposals } from "../../utils/swrHooks";
import {
  useCheckoutAttachments,
  useOfferList,
  useProspect,
} from "../../checkout/hooks/useCheckoutSwr";
import { ListOffer } from "../../../amplify/backend/function/offerexpstoreFrontApi/ts/public/offerTypes";
import { getEnv } from "utils/env";

interface CapContextType {
  cap: Cap | undefined;
  documents: CapDoc[] | undefined;
  prospectId: string | undefined;
  prospect: any | undefined;
  contacts: SplatContact[] | undefined;
  sow: Sow | undefined;
  originalProposal: SplatProposal | undefined;
  activeProposal: Proposal | undefined;
  activeProposalDesignImages: any;
  originalProposalDesignImages: any;
  documentsError: any;
  isDocumentsLoading: any;
  sfdcUrl: string;
  oneBaseUrl: string;
  splatBaseUrl: string;
  reviewOfferId: string;
  capTask: any;
  equipmentLocationImages: any;
}

const CapContext = createContext<CapContextType>({
  cap: undefined,
  documents: [],
  prospectId: undefined,
  prospect: undefined,
  contacts: undefined,
  sow: undefined,
  originalProposal: undefined,
  activeProposal: undefined,
  activeProposalDesignImages: undefined,
  originalProposalDesignImages: undefined,
  documentsError: undefined,
  isDocumentsLoading: undefined,
  sfdcUrl: "",
  oneBaseUrl: "",
  splatBaseUrl: "",
  reviewOfferId: "",
  capTask: undefined,
  equipmentLocationImages: undefined,
});

const useCapContext = (): CapContextType => {
  const context = useContext(CapContext);
  if (!context) {
    rollbar.error(
      "[<CapContext>] -- useCapContext must be used within a CapContextProvider"
    );
    throw new Error("useCapContext must be used within a CapContextProvider");
  }
  return context;
};

const CapContextProvider: React.FC<React.PropsWithChildren> = ({
  children,
}) => {
  const { prospectId } = useParams();
  const authKey = useAppSelector((state) => state?.auth?.hybridToken);
  const cognitoToken = useAppSelector((state) => state?.auth?.cognitoToken);
  const { data: prospect } = useProspect(prospectId);
  const { cap } = useCap(authKey, prospectId);
  const capTask = cap?.salesCapTasks?.find?.(
    (task: any) => task.status !== "Inactive"
  );
  const capName = capTask?.name;
  const capLetterType = capName?.match(/\(([A-Z])\)$/)?.[1] as CapType;

  if (cap) {
    cap.type = capLetterType;
  }
  const { user } = useUserProfile(authKey);

  let ENV: any = process.env.NODE_ENV ?? "";
  try {
    ENV = getEnv();
  } catch (e) {
    console.error("get environment error", e);
  }

  const {
    oneBaseUrl,
    salesforceBaseUrl,
    sfPartnerBaseUrl,
    sfdcEnv,
    splatBaseUrl,
  } = useMemo(() => {
    return {
      splatBaseUrl:
        ENV === "production"
          ? "https://go.sunrun.com"
          : "https://majstg-go.sunrun.com",
      oneBaseUrl:
        ENV === "production"
          ? "https://sunrunone.com"
          : "https://staging.sunrunone.com",
      salesforceBaseUrl:
        ENV === "production"
          ? "https://sunrun.my.salesforce.com"
          : "https://sunrun--majstg.sandbox.my.salesforce.com",
      sfPartnerBaseUrl:
        ENV === "production"
          ? "https://sunrun.my.site.com"
          : "https://sunrun--majstg.sandbox.my.site.com",
      sfdcEnv: (user && getSfdcEnv(user)) || "SFDC",
    };
  }, [ENV, user]);

  const getSfdcBaseUrl = (
    sfdcEnv: SfdcEnv,
    sfPartnerBaseUrl: string,
    salesforceBaseUrl: string
  ): string => {
    let result = "";
    if (!sfdcEnv) return result;
    switch (sfdcEnv) {
      case "Community": {
        result = `${sfPartnerBaseUrl}/partners`;
        break;
      }
      case "D2HCommunity": {
        result = `${sfPartnerBaseUrl}/d2hpartnercommunity`;
        break;
      }
      case "SFDC":
      case "None":
      default: {
        result = salesforceBaseUrl;
        break;
      }
    }
    return result;
  };

  const { contacts } = useContactsData(prospectId ?? "", authKey);
  const { documents, documentsError, isDocumentsLoading } = useCapDocs(
    authKey,
    prospectId
  );
  const { sow } = useSow(authKey, prospectId ?? "");
  const activeProposal = sow?.proposal;
  const { data: activeProposalDesignImages } = useCheckoutAttachments(
    prospectId!,
    sow?.proposalName!,
    "Design Image"
  );

  const [reviewOfferId, setReviewOfferId] = useState("");

  const { offerList } = useOfferList(prospectId ?? "", cognitoToken?.token);

  const equipmentLocationImages = sow?.allSharinPixImages.filter(
    (image: any) => {
      return (
        image.sharinpix__DisplayTags__c?.includes("CAP Design Summary") &&
        (image.sharinpix__DisplayTags__c
          ?.toLowerCase()
          .includes("equipment location") ||
          image.sharinpix__DisplayTags__c?.includes("Equipment"))
      );
    }
  );

  useEffect(() => {
    if (prospectId && offerList && offerList.length > 0) {
      (offerList as ListOffer[]).forEach((offer) => {
        if (offer.isSigned && offer.status !== "INACTIVE") {
          setReviewOfferId(offer.id);
        }
      });
    }
  }, [offerList, prospectId]);

  const { data: proposals } = useProposals(prospectId!, authKey);

  const findOriginalProposal = () => {
    if (proposals?.length! > 0 && sow?.lastCustomerSignedProposalId) {
      return proposals?.filter((p: SplatProposal) => {
        return p.id === sow.lastCustomerSignedProposalId;
      })[0];
    }
  };

  const originalProposal = findOriginalProposal();

  const { data: originalProposalDesignImages } = useCheckoutAttachments(
    prospectId!,
    originalProposal?.proposalId ?? "",
    "Design Image"
  );

  const sfdcUrl =
    getSfdcBaseUrl(sfdcEnv, sfPartnerBaseUrl, salesforceBaseUrl) ?? "";

  const capContextValue = useMemo(() => {
    return {
      cap,
      prospectId,
      prospect,
      contacts,
      documents,
      sow,
      originalProposal,
      activeProposal,
      activeProposalDesignImages,
      originalProposalDesignImages,
      documentsError,
      isDocumentsLoading,
      sfdcUrl,
      oneBaseUrl,
      splatBaseUrl,
      reviewOfferId,
      capTask,
      equipmentLocationImages,
    } as CapContextType;
  }, [
    cap,
    prospectId,
    prospect,
    contacts,
    documents,
    sow,
    originalProposal,
    activeProposal,
    activeProposalDesignImages,
    originalProposalDesignImages,
    documentsError,
    isDocumentsLoading,
    sfdcUrl,
    oneBaseUrl,
    splatBaseUrl,
    reviewOfferId,
    capTask,
    equipmentLocationImages,
  ]);

  return (
    <CapContext.Provider value={capContextValue}>
      {children}
    </CapContext.Provider>
  );
};

export { useCapContext, CapContextProvider };
export type { CapContextType };

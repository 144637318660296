import {
  Button,
  Card,
  CardHeader,
  LoadingIndicator,
  Modal,
  Pill,
  Snackbar,
  SvgNames,
  tokens,
  Typography,
} from "@sunrun/experience-ui-components";
import React, { useCallback, useEffect, useState } from "react";
import styled from "@emotion/styled/macro";
import { ModalButtonProp } from "@sunrun/experience-ui-components/lib/components/Modal";
import { useFlags } from "flagsmith/react";
import { useCapContext } from "../../providers/CapContext";
import { AutopayEnrollmentForm } from "../molecules/AutopayEnrollmentForm";
import { formatCurrency } from "../../../checkout/util/constants";
import { RadioCard } from "../../../checkout/components/atoms/RadioCard";
import { CapDoc, VivintEligibilityResponse } from "../../capTypes";
import { ContentLeft, ContentRight, Flex } from "../atoms/layoutAtoms";
import { SplatContact } from "../../../../amplify/backend/function/OfferExpContacts/ts/public/types";
import { postDocumentEmail } from "../../api/capApi";
import { useAppSelector } from "../../../store";
import { SlimContactCard } from "../../../checkout/components/molecules/SlimContactCard";
import { rollbar } from "../../../providers/rollbar";
import { postPaymentRequestEmail } from "../../../services/prospect";
import { checkVSHEligibility } from "../../../services/vivintEligibility";
import { useAuthToken } from "../../../hooks/useAuthToken";
import { getEnv } from "utils/env";
import { FeatureFlags } from "fixtures/features";

const ReadSignStepView: React.FC = () => {
  const { sow, activeProposal, documents, prospectId, prospect, contacts } =
    useCapContext();
  const authKey = useAppSelector((state) => state?.auth?.hybridToken);

  const authToken = useAuthToken("IHD");

  const [showAutopaySection, setShowAutopaySection] = useState(false);
  const [showBankForm, setShowBankForm] = useState(false);
  const [showAutopayForm, setShowAutopayForm] = useState(false);
  const [showAutopaySuccess, setShowAutopaySuccess] = useState(false);
  const [autopaySuccessResponse, setAutopaySuccessResponse] =
    useState(undefined);

  const [vivintData, setVivintData] = useState<
    VivintEligibilityResponse | undefined
  >();

  const flags = useFlags([FeatureFlags.VIVINT_SMART_HOME_UI]);
  const canViewVivintSmartHome =
    flags[FeatureFlags.VIVINT_SMART_HOME_UI]?.enabled;

  const [paymentType, setPaymentType] = useState("echeck");
  const AUTOPAY_LINK = `https://www.sunrun.com/autopay`;
  const [documentError, setDocumentError] = useState<Error | undefined>(
    undefined
  );
  const [documentSendInProgress, setDocumentSendInProgress] = useState(false);
  const [documentToSend, setDocumentToSend] = useState<CapDoc | undefined>(
    undefined
  );
  const [showConfirmEmail, setShowConfirmEmail] = useState(false);
  const [showEmailSuccessToast, setShowEmailSuccessToast] = useState(false);

  const handleDocumentEmail = useCallback(async () => {
    const sendDocumentEmail = async (document: any, prospectId: string) => {
      const documentEmail = {
        primaryContactId:
          contacts?.filter((c: SplatContact) => c.primary)[0].contactId ?? "",
        customerSigned: document.customerSigned,
        documentType: document.documentType,
        srAttachmentId: document.documentId,
      };
      await postDocumentEmail(authKey, documentEmail, prospectId);
    };

    setDocumentSendInProgress(true);
    setShowConfirmEmail(false);

    try {
      if (documentToSend) {
        await sendDocumentEmail(documentToSend, prospectId ?? "");
        setShowEmailSuccessToast(true);
      }
    } catch (e) {
      setDocumentError(e as Error);
    } finally {
      setDocumentSendInProgress(false);
      setDocumentToSend(undefined);
    }
  }, [authKey, contacts, documentToSend, prospectId]);

  useEffect(() => {
    if (!authToken?.token || !prospectId || !canViewVivintSmartHome) {
      return;
    }
    checkVSHEligibility(authToken.token, prospectId)
      .then((vshData) => {
        setVivintData(vshData);
      })
      .catch((error) => {
        rollbar.error(error);
      });
  }, [authToken, prospectId, canViewVivintSmartHome]);

  const isAutopayEnrolled =
    sow?.billingMethod?.includes("Recurring") ||
    sow?.paymentMode?.includes("Recurring") ||
    autopaySuccessResponse;

  useEffect(() => {
    if (activeProposal) {
      if (
        activeProposal.dealType === "FlexMonthly PPA" ||
        activeProposal.dealType === "FlexMonthly" ||
        activeProposal.dealType === "monthly"
      ) {
        setShowAutopaySection(true);

        if (isAutopayEnrolled) {
          setShowAutopaySuccess(true);
        } else {
          setShowBankForm(true);
        }
      }
    }

    if (
      sow &&
      (sow.paymentMode === "Recurring CC" ||
        sow.billingMethod === "Recurring CC")
    ) {
      setPaymentType("card");
    }
  }, [
    isAutopayEnrolled,
    activeProposal,
    autopaySuccessResponse,
    paymentType,
    sow,
  ]);

  useEffect(() => {
    if (showAutopayForm) {
      setShowBankForm(false);
      setShowAutopaySuccess(false);
    }
  }, [showAutopayForm]);

  let monthlyPayment: string | undefined;
  if (activeProposal) {
    switch (activeProposal.dealType) {
      case "monthly":
      case "Monthly":
        monthlyPayment = formatCurrency(activeProposal.monthlySunrunBill);
        break;
      case "FlexMonthly":
        if (
          activeProposal.year1MinimumMonthlyBill &&
          activeProposal.year1MaximumMonthlyBill
        ) {
          monthlyPayment = `${formatCurrency(
            activeProposal.year1MinimumMonthlyBill
          )} - ${formatCurrency(activeProposal.year1MaximumMonthlyBill)}`;
        }
        break;
    }
  }

  const confirmEmailProps: ModalButtonProp = {
    text: "Send",
    onClick: handleDocumentEmail,
  };

  const cancelEmailProps: ModalButtonProp = {
    text: "Cancel",
    onClick: () => setDocumentToSend(undefined),
  };

  const readDocument = useCallback((doc: CapDoc) => {
    window.open(doc.documentLink, "_blank");
  }, []);

  const [confirmEmailAutopay, setConfirmEmailAutopay] = useState(false);
  const [emailLoading, setEmailLoading] = useState(false);
  const [showEmailSuccessSnackbar, setShowEmailSuccessSnackbar] =
    useState(false);

  const handleSendAutopayEmail = useCallback(async () => {
    setEmailLoading(true);
    let result;
    try {
      result = await postPaymentRequestEmail(authKey, prospectId!, "Recurring");
    } catch (e) {
      rollbar.error(e as Error);
    } finally {
      setConfirmEmailAutopay(false);
      setEmailLoading(false);
      if (result?.message === "Email sent successfully") {
        setShowEmailSuccessSnackbar(true);
      }
    }
  }, [authKey, prospectId]);

  const openVivintScheduler = () => {
    const url =
      getEnv() === "production"
        ? `https://designtools.sunrun.com/vsh/prospect/${prospectId}/scheduler`
        : `https://devdesigntools.sunrun.com/vsh/prospect/${prospectId}/scheduler`;

    window.open(url, "_blank");
  };

  const showVivintScheduler = vivintData?.eligibility?.isEligible ?? false;

  useEffect(() => {
    if (!!documentToSend) {
      setShowConfirmEmail(true);
    }
  }, [documentToSend]);

  return (
    <AgreementsContainer>
      <Row>
        <BaseSidebarText>
          <Typography variant="h4">Sign your documents</Typography>
          <Typography>We are excited to work with you!</Typography>
        </BaseSidebarText>

        {documents && prospect && (
          <BaseContainer>
            {documents.map((doc: CapDoc, index) => {
              return (
                <CapDocument key={index}>
                  <CardHeader>
                    <ContentLeft>
                      <Typography
                        color={tokens.BRAND_HERO_BLUE}
                        size={20}
                        fontWeight={"medium"}
                      >
                        {doc.documentType === "CAP Package"
                          ? "Design approval agreement"
                          : doc.documentType}
                      </Typography>
                    </ContentLeft>
                    {doc.customerSigned === "Customer Signed" ? (
                      <ContentRight>
                        <Pill type="success" text="Signed"></Pill>
                      </ContentRight>
                    ) : (
                      <ContentRight>
                        <Pill
                          type="info"
                          icon={SvgNames.PenToolSvg}
                          text="Ready to Sign"
                        />
                      </ContentRight>
                    )}
                  </CardHeader>

                  {doc.documentType === "CAP Package" && (
                    <Typography>
                      Please review the terms of your solar agreement with
                      Sunrun
                    </Typography>
                  )}
                  <Flex>
                    <ContentLeft>
                      <UnderlinedButton onClick={() => readDocument(doc)}>
                        <Typography
                          size={16}
                          lineHeight={24}
                          fontWeight={"medium"}
                        >
                          Read
                        </Typography>
                      </UnderlinedButton>
                    </ContentLeft>
                    <ContentRight>
                      <RoundedButton
                        disabled={documentSendInProgress}
                        onClick={() => setDocumentToSend(doc)}
                      >
                        {documentSendInProgress && documentToSend === doc ? (
                          <LoadingIndicator showLoadingMessage={false} />
                        ) : (
                          <span>Email</span>
                        )}
                      </RoundedButton>
                    </ContentRight>
                  </Flex>
                  {showConfirmEmail && (
                    <Modal
                      title={"Please Confirm"}
                      primaryButton={confirmEmailProps}
                      secondaryButton={cancelEmailProps}
                      type={"info"}
                      hideIcon={true}
                      onClose={() => setShowConfirmEmail(false)}
                    >
                      <Typography>
                        {documentToSend?.documentType} email will be sent to{" "}
                        {prospect.customerEmail}
                      </Typography>
                    </Modal>
                  )}
                </CapDocument>
              );
            })}
            {documentError && (
              <Modal
                title={"Error sending document email"}
                type={"error"}
                onClose={() => setDocumentError(undefined)}
              >
                <div>{documentError.message}</div>
              </Modal>
            )}
            <Snackbar
              type={"success"}
              open={showEmailSuccessToast}
              onClose={() => setShowEmailSuccessToast(false)}
              message={"Email sent successfully"}
            />
          </BaseContainer>
        )}
      </Row>
      {!!sow?.priceToCustomer && (
        <Row className="sow-financing-row">
          <BaseSidebarText>
            <Typography variant="h4">Optional Home Upgrade Loan</Typography>
            <Typography>
              For customers who want to finance their home upgrade costs and
              said yes to financing in their design approval agreement.
            </Typography>
          </BaseSidebarText>
          <BaseContainer>
            <Card>
              <CardHeader>
                <Typography size={19} fontWeight={"medium"}>
                  Optional Home Upgrade Loan
                </Typography>
              </CardHeader>
              <Typography>
                Your sales rep will fill out a form and then send the loan
                documents to you for signing. To cover your home upgrade costs
                of ${sow?.priceToCustomer}
              </Typography>
              <Row style={{ justifyContent: "flex-end" }}>
                <ContinueAutopayButton
                  color={"primary"}
                  onClick={() =>
                    window.open(
                      "https://www.goodleap.dev/sunrun/08bd442a-0503-4a27-b83f-0427ab38f8c0",
                      "_blank"
                    )
                  }
                >
                  Prepare loan docs
                </ContinueAutopayButton>
              </Row>
            </Card>
          </BaseContainer>
        </Row>
      )}
      {showVivintScheduler && canViewVivintSmartHome && (
        <Row>
          <BaseSidebarText>
            <Typography variant="h4">Get Vivint Smart Home</Typography>
            <Typography>
              You qualified to get a free Vivint Smart Home with your Sunrun
              system. Schedule an installation to get started.
            </Typography>
          </BaseSidebarText>
          <BaseContainer>
            <Card>
              <CardHeader>
                <Typography size={19} fontWeight="medium">
                  Vivint Smart Home installation
                </Typography>
              </CardHeader>

              <Typography>
                A technician will visit your home to install your Vivint Smart
                Home.
              </Typography>

              <Flex>
                <RoundedButton
                  style={{ marginLeft: "auto" }}
                  disabled={false}
                  onClick={openVivintScheduler}
                >
                  Schedule installation
                </RoundedButton>
              </Flex>
            </Card>
          </BaseContainer>
        </Row>
      )}
      {showAutopaySection && (
        <Row className="autopay-row">
          <BaseSidebarText>
            <Typography variant="h4">Enroll in autopay</Typography>
            <Typography>
              Your quoted first year monthly bill includes the discount you
              receive when you sign up for ACH autopay.
            </Typography>
            <Typography>
              For exact discount details, please refer to your customer
              agreement. If you have questions, please visit{" "}
              <a href={AUTOPAY_LINK} rel="noreferrer noopener" target="_blank">
                www.sunrun.com/autopay
              </a>
            </Typography>
          </BaseSidebarText>
          {prospect && contacts && (
            <BaseContainer>
              {!isAutopayEnrolled && showBankForm && (
                <Card>
                  <CardHeader>
                    <Typography size={19} fontWeight={"medium"}>
                      Autopay Details
                    </Typography>
                  </CardHeader>
                  <SmashedPumpkins>
                    <Typography
                      size={16}
                      fontWeight={"medium"}
                      color={tokens.BRAND_HERO_BLUE}
                    >
                      Autopay amount
                    </Typography>
                    <Typography
                      size={28}
                      fontWeight={"medium"}
                      color={tokens.BRAND_HERO_BLUE}
                    >
                      {monthlyPayment}/mo
                    </Typography>
                    <Typography
                      size={14}
                      color={tokens.OFFWHITE_10}
                      fontWeight={"regular"}
                    >
                      Price includes discount for enrolling in ACH Autopay
                    </Typography>
                  </SmashedPumpkins>
                  {!showAutopaySuccess && showBankForm && (
                    <FlexGap>
                      <Typography color={tokens.BRAND_HERO_BLUE}>
                        Payment Method
                      </Typography>
                      <RadioCard
                        labelElement={
                          <LabelContainer role="label">
                            <Typography
                              size={tokens.FONT_SIZE_3}
                              color={tokens.BRAND_HEROBLUE}
                              fontWeight="medium"
                            >
                              Checking
                            </Typography>
                            <Typography
                              color={tokens.TINTS_OFF_WHITE_20}
                              size={tokens.FONT_SIZE_3}
                            >
                              Recurring bank transfer
                            </Typography>
                          </LabelContainer>
                        }
                        checked={paymentType === "echeck"}
                        handleChange={() => setPaymentType("echeck")}
                      />
                      <RadioCard
                        labelElement={
                          <LabelContainer role="label">
                            <Typography
                              size={tokens.FONT_SIZE_3}
                              color={tokens.BRAND_HEROBLUE}
                              fontWeight="medium"
                            >
                              Savings
                            </Typography>
                            <Typography
                              color={tokens.TINTS_OFF_WHITE_20}
                              size={tokens.FONT_SIZE_3}
                            >
                              Recurring bank transfer
                            </Typography>
                          </LabelContainer>
                        }
                        checked={paymentType === "savings"}
                        handleChange={() => setPaymentType("savings")}
                      />
                      <Row style={{ justifyContent: "flex-end" }}>
                        <EmailAutopayButton
                          color={"secondary"}
                          onClick={() => setConfirmEmailAutopay(true)}
                        >
                          Email autopay
                        </EmailAutopayButton>
                        <ContinueAutopayButton
                          onClick={() => setShowAutopayForm(true)}
                        >
                          Continue with autopay
                        </ContinueAutopayButton>
                      </Row>
                    </FlexGap>
                  )}
                  {confirmEmailAutopay && (
                    <Modal
                      title={"Email autopay form"}
                      type={"info"}
                      hideIcon={true}
                      onClose={() => setConfirmEmailAutopay(false)}
                    >
                      {emailLoading ? (
                        <LoadingIndicator
                          color={"black"}
                          showLoadingMessage={false}
                        />
                      ) : (
                        <>
                          <Typography
                            style={{ marginBottom: 24 }}
                            size={20}
                            lineHeight={26}
                          >
                            The autopay form will be sent to the email address
                            below.
                          </Typography>
                          <SlimContactCard
                            showEmail={true}
                            contact={
                              contacts.find((c) => c.primary) ?? contacts[0]
                            }
                          />
                          <Row style={{ justifyContent: "flex-end" }}>
                            <Button
                              color={"secondary"}
                              onClick={() => setConfirmEmailAutopay(false)}
                            >
                              Cancel
                            </Button>
                            <Button onClick={handleSendAutopayEmail}>
                              Send email
                            </Button>
                          </Row>
                        </>
                      )}
                    </Modal>
                  )}
                  <Snackbar
                    open={showEmailSuccessSnackbar}
                    autoHideDuration={4000}
                    colorScheme="dark"
                    message={`Autopay form sent successfully!`}
                    onClose={() => setShowEmailSuccessSnackbar(false)}
                    type="success"
                  />
                </Card>
              )}
              <AutopayEnrollmentForm
                autopayPaymentAmount={monthlyPayment?.toString() ?? ""}
                prospect={prospect}
                contacts={contacts}
                paymentType={paymentType}
                showForm={showAutopayForm}
                setShowForm={setShowAutopayForm}
                showBankForm={showBankForm}
                showSuccess={showAutopaySuccess}
                setSuccessResponse={(data) => setAutopaySuccessResponse(data)}
              />
            </BaseContainer>
          )}
        </Row>
      )}
    </AgreementsContainer>
  );
};

const BaseSidebarText = styled.div`
  display: flex;
  flex-direction: column;
  width: 30%;
  gap: 12px;
`;

const UnderlinedButton = styled(Button)`
  background: unset;
  border: 0;
  border-radius: unset;
  color: ${tokens.BRAND_HERO_BLUE};
  border-bottom: 2px solid ${tokens.BRAND_HERO_BLUE};
  padding: 0;
`;

const FlexGap = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const EmailAutopayButton = styled(Button)`
  width: fit-content;
  padding: 8px 48px !important;
`;

const ContinueAutopayButton = styled(Button)`
  width: fit-content;
  padding: 8px 48px;
`;

const CapDocument = styled(Card)``;

const Row = styled.div`
  padding-top: 5%;
  display: flex;
  flex-direction: row;
  gap: 12px;
`;

const RoundedButton = styled(Button)`
  padding: 8px 48px;
`;

const BaseContainer = styled.div`
  width: 70%;
  display: flex;
  flex-direction: column;
  gap: 12px;
`;

const SmashedPumpkins = styled(Card)`
  border: unset;

  & > p {
    margin: unset !important;
  }
`;

const LabelContainer = styled.div`
  display: flex;
  gap: 8px;
`;

const AgreementsContainer = styled.section``;

export { ReadSignStepView };

import styled from "@emotion/styled/macro";

const Disclaimer = () => {
  return (
    <>
      <p>
        <b>Proposals</b>: This proposal is an estimate and does not guarantee
        actual system production or savings. The system design may change based
        on a detailed engineering site survey. Actual system production and
        savings will vary based on the final system size, design, configuration,
        utility rates, applicable rebates, actual sales tax owed by you, and
        your family’s energy usage.
      </p>
      <p>
        <b>System Design</b>: The system design may change based on a detailed
        engineering site audit. Actual system production, offset and savings
        will vary based on the final system size, design, configuration, utility
        rates, applicable rebates, system location, local climate, and your
        family’s energy usage.
      </p>
      <p>
        <b>Energy Rate</b>: Sunrun estimates your implied year 1 energy rate
        with your utility by dividing your estimated annual utility bill by your
        estimated annual energy consumption. Similarly, we calculate your
        implied year 1 Sunrun energy rate by dividing your annual Sunrun bill by
        your solar system’s estimated annual production.
      </p>
      <p>
        <b>Utility Yearly Increase</b>: Yearly increase is an estimate only and
        is subject to change.
      </p>
      <p>
        <b>Savings</b>: Under Net Energy Metering (“NEM”) 3.0, solar customers
        are required to be on a time-of-use ("TOU") tariff and to pay
        non-bypassable charges for all of their energy, including exports. This
        savings calculation assumes the customer is on a TOU tariff after going
        solar, and the post-solar bills are based on a typical customer's
        consumption and solar production load profile.
      </p>
      <p>
        <b>Sales Tax</b>: This number is an estimate of your anticipated sales
        and/or local tax.
      </p>
      <p>
        <b>SREC</b>: Depending on the jurisdiction in which your system is
        located, your system may produce solar renewable energy credits
        (“SRECs”). Please consult&nbsp;
        <a href="https://srectrade.com/" target="_blank" rel="noreferrer">
          SRECTrade | Home
        </a>{" "}
        and&nbsp;
        <a
          href="https://resource-solutions.org/wp-content/uploads/2015/07/Guidelines-for-Renewable-Energy-Claims.pdf"
          target="_blank"
          rel="noreferrer"
        >
          https://resource-solutions.org/wp-content/uploads/2015/07/Guidelines-for-Renewable-Energy-Claims.pdf
        </a>
        &nbsp;for more information about SRECs. Please consult your agreement to
        see how SRECs produced by your system will be treated. Estimate only.
        Sunrun does not facilitate the sale of SRECs, nor guarantee the number
        produced or market price. You are solely responsible for registering
        your PV system with your applicable SREC Program Administrator and for
        all sales of SRECs. SREC values are subject to actual system production
        in future dates; changes in the SREC program and changes to SREC market
        conditions, SREC laws, regulations and policies; and future SREC price
        changes.
      </p>
      <p>
        <b>SGIP</b>: Sunrun pricing is contingent upon Sunrun filing for and
        collecting the SGIP storage incentive, which will require the customer
        to provide documentation and a signature allowing Sunrun to claim the
        incentive on his/her behalf.
      </p>
      <p>
        <b>Federal Solar Tax Credit</b>: The federal solar tax credit gives you
        a dollar-for-dollar reduction against your federal income tax. You must
        file for the Federal Solar Tax Credit in your next federal income tax
        return. Amount is estimated. The purchaser of a solar system may qualify
        for certain federal, state, local or other rebates, tax credits or
        incentives. No representation or warranty is made as to whether such
        credits will be available to the purchaser. Please consult a qualified
        tax professional for assistance and details.
      </p>
      <p>
        <b>State and Local Rebates and Credits</b>: You are solely responsible
        for claiming the state and local tax credit or rebate.
      </p>
      <p>
        <b>Costco Cash Card</b>: Costco Cash Card amount is an estimate only and
        will be verified by Sunrun prior to system installation. The amount is
        based on the pre-tax price of the modules, racking, and inverters used
        for your system. Installation is not included.
      </p>
      <p>
        <ACPTable data-testid="offer-experience--Disclaimer--modal--acpTable">
          <thead>
            <tr>
              <th>State</th>
              <th>Utility</th>
              <th>ACP Disclaimer</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>CA</td>
              <td>PG&E</td>
              <td>
                Savings: Under Net Energy Metering (&quot;NEM&quot;) 3.0, solar
                customers are required to be on a time-of-use (&quot;TOU&quot;)
                tariff and to pay non-bypassable charges for all of their
                energy, including exports. This savings calculation assumes the
                customer is on a TOU tariff after going solar, and the
                post-solar bills are based on a typical customer&apos;s
                consumption and solar production load profile.
              </td>
            </tr>
            <tr>
              <td>CA</td>
              <td>SCE</td>
              <td>
                Savings: Under Net Energy Metering (&quot;NEM&quot;) 3.0, solar
                customers are required to be on a time-of-use (&quot;TOU&quot;)
                tariff and to pay non-bypassable charges for all of their
                energy, including exports. This savings calculation assumes the
                customer is on a TOU tariff after going solar, and the
                post-solar bills are based on a typical customer&apos;s
                consumption and solar production load profile.
              </td>
            </tr>
            <tr>
              <td>CA</td>
              <td>SDG&E</td>
              <td>
                Savings: Under Net Energy Metering (&quot;NEM&quot;) 3.0, solar
                customers are required to be on a time-of-use (&quot;TOU&quot;)
                tariff and to pay non-bypassable charges for all of their
                energy, including exports. This savings calculation assumes the
                customer is on a TOU tariff after going solar, and the
                post-solar bills are based on a typical customer&apos;s
                consumption and solar production load profile.
              </td>
            </tr>
            <tr>
              <td>HI</td>
              <td>HECO</td>
              <td>
                This post-solar utility bill estimate assumes the customer is on
                a time-of-use (&quot;TOU&quot;) SMART DER export tariff after
                going solar, and the post-solar bills are based on a typical
                customer&apos;s consumption and solar production load profile.
              </td>
            </tr>
            <tr>
              <td>HI</td>
              <td>HELCO</td>
              <td>
                This post-solar utility bill estimate assumes the customer is on
                a time-of-use (&quot;TOU&quot;) SMART DER export tariff after
                going solar, and the post-solar bills are based on a typical
                customer&apos;s consumption and solar production load profile.
              </td>
            </tr>
            <tr>
              <td>HI</td>
              <td>MECO</td>
              <td>
                This post-solar utility bill estimate assumes the customer is on
                a time-of-use (&quot;TOU&quot;) SMART DER export tariff after
                going solar, and the post-solar bills are based on a typical
                customer&apos;s consumption and solar production load profile.
              </td>
            </tr>
            <tr>
              <td>HI</td>
              <td>KIUC</td>
              <td>
                This post-solar utility bill estimate assumes the customer is on
                the Schedule D tariff after going solar, and the post-solar
                bills are based on a typical customer&apos;s consumption and
                solar production load profile.
              </td>
            </tr>
          </tbody>
        </ACPTable>
      </p>
      <p>
        <b>Contractor License Information</b>: AZ 294836; CA CSLB 750184/969975;
        CO EC.0007080/180464; CT ELC.0200835-E1/HIC.0642655; DC ECC
        904168/420216000005; FL EC13007964; HI CT-28011/CT-35178; IL 18-0817; MA
        1141/178937/HIC.180120; MD 10953916/HIC 109813/HIC 13259; NM 392099; NH
        0376C; NJ 34EI01574500/3EB01539300/
        <br />
        34EI01539300/HIC.13VH05700500; NV 0079413/HIC 180120; NYC HIC
        2015496-DCA; NYC Electrician #12610; OR 180464; PA PA075650/PA112037; PR
        APM-535; RI A-003621; SC M113485 MC/113485/RBS 57579; TX 28447; UT
        10094018-5501/9398170-550; VT EM-06461; WI 1410087/1410088. See&nbsp;
        <a href="https://www.sunrun.com/state-contractor-license-information">
          State contractor license information
        </a>
        &nbsp;for other contractor licenses.
      </p>
      <p> ©2018 Sunrun, Inc. All rights reserved.</p>
    </>
  );
};

const ACPTable = styled.table`
  th,
  td {
    padding: 0.5rem;
  }
`;

export { Disclaimer };

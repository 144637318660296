import {
  Button,
  SvgIcon,
  SvgNames,
  tokens,
  Typography,
} from "@sunrun/experience-ui-components";
import React, { useCallback, useState } from "react";
import styled from "@emotion/styled/macro";

type HamburgerMenuProps = {
  fries: string[][];
};

const HamburgerMenu = ({ fries }: HamburgerMenuProps) => {
  const [showFries, setShowFries] = useState(false);
  const goToLinkCloseMenu = useCallback((url: string) => {
    window.open(url, "_blank");
    setShowFries(false);
  }, []);
  return (
    <>
      <Hamburger onClick={() => setShowFries(!showFries)}>
        <SvgIcon
          style={{ marginTop: "15%" }}
          width={24}
          height={24}
          color={tokens.BRAND_MEDIUM_BLUE}
          name={SvgNames.VerticalDotsSvg}
        />
      </Hamburger>
      {showFries && (
        <Fries>
          {fries.map((fry, index) => {
            return (
              <Fry
                index={index}
                key={index}
                role={"button"}
                onClick={() => goToLinkCloseMenu(fry[0])}
              >
                <Typography fontWeight={"medium"} size={16} lineHeight={24}>
                  {fry[1]}
                </Typography>{" "}
                <SvgIcon
                  width={16}
                  height={16}
                  color={"black"}
                  name={SvgNames.ExternalLink}
                />
              </Fry>
            );
          })}
        </Fries>
      )}
    </>
  );
};

const Hamburger = styled(Button)`
  background: unset;
  display: flex;
  border: 1px solid ${tokens.BRAND_MEDIUM_BLUE};
  width: 32px;
  height: 32px;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  align-content: center;
  padding: 0;

  & > span {
    margin: unset !important;
  }
`;

const Fries = styled.ul`
  list-style: none;
  background-color: ${tokens.TINTS_OFF_WHITE_90};
  position: absolute;
  right: 64px;
  margin-top: 46px;
  border-radius: 8px;
  border: 1px solid ${tokens.OFFWHITE_30};
  padding-inline-start: 0;
  z-index: 1005;
`;

const Fry = styled.li<{ index: number }>`
  display: flex;
  padding: 16px 18px;
  gap: 8px;

  ${({ index }) =>
    index !== 0 ? `border-top: 1px solid ${tokens.OFFWHITE_30};` : ""}
  & > svg {
    margin-left: 10px;
  }
`;

export { HamburgerMenu };

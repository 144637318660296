import React, { useState } from "react";
import {
  Button,
  LoadingIndicator,
  Modal,
  tokens,
  Typography,
} from "@sunrun/experience-ui-components";
import { useParams } from "react-router-dom";
import styled from "@emotion/styled/macro";
import { rollbar } from "../../../providers/rollbar";
import { useAppSelector } from "../../../store";
import { postPropspectProposalEmail } from "../../api/capApi";
import { HomeReadinessStepView } from "../organisms/HomeReadinessStepView";
import { ReadSignStepView } from "../organisms/ReadSignStepView";
import { DesignStepView } from "../organisms/DesignStepView";
import { useCapContext } from "../../providers/CapContext";
import heroImg from "../../assets/hero-image.svg";
import triangleOverlay from "../../assets/triangle_overlay.svg";
import { CapNav } from "../molecules/CapNav";
import { NextSteps } from "../organisms/NextStepsStepView";
import sunrunMobile from "../../assets/sunrunMobile.svg";
import AppleAppStore from "../../assets/AppleAppStore.svg";
import DownloadAppQR from "../../assets/DownloadAppQR.svg";
import GooglePlayStore from "../../assets/GooglePlayStore.svg";
import iPhone from "../../assets/iPhone.png";
import iPhoneAutopay from "../../assets/iPhone-autopay.svg";
import { EquipmentLocationStepView } from "../organisms/EquipmentLocationStepView";
import { StepName } from "../../capTypes";
import { STEPS } from "../../constants/capConstants";

const CapPage: React.FC = () => {
  const { prospectId = "" } = useParams();
  const authKey = useAppSelector((state) => state?.auth?.hybridToken);
  const [confirmEmail, setConfirmEmail] = useState(false);
  const [isEmailSending, setIsEmailSending] = useState(false);
  const [currentStep, changeStep] = useState<StepName>(STEPS.SYSTEM_DESIGN);
  const { prospect, contacts, activeProposal } = useCapContext();

  const showAutopay =
    activeProposal?.dealType === "FlexMonthly PPA" ||
    activeProposal?.dealType === "FlexMonthly" ||
    activeProposal?.dealType === "monthly";

  const sendProspectProposal = async () => {
    setIsEmailSending(true);
    try {
      await postPropspectProposalEmail(authKey, prospectId, "notes");
    } catch (e: any) {
      rollbar.error(e);
    } finally {
      setConfirmEmail(false);
      setIsEmailSending(false);
    }
  };

  type CapPresentationProps = {
    stepName: StepName;
  };

  const CapPresentationContent = ({ stepName }: CapPresentationProps) => {
    switch (stepName) {
      case STEPS.SYSTEM_DESIGN:
        return <DesignStepView />;
      case STEPS.HOME_READINESS:
        return <HomeReadinessStepView />;
      case STEPS.READ_SIGN:
        return <ReadSignStepView />;
      case STEPS.NEXT_STEPS:
        return <NextSteps />;
      case STEPS.EQUIPMENT_LOCATION:
        return <EquipmentLocationStepView />;
      default:
        return <div>Unknown Step Name</div>;
    }
  };

  const primaryContact = contacts?.filter((c) => c.primary)[0];

  return (
    <>
      {prospect && (
        <CapPageHeader>
          <TriangleOverlay src={triangleOverlay} />
          <Typography
            style={{ marginTop: 111, zIndex: 2, position: "relative" }}
            color={tokens.TINTS_OFF_WHITE_90}
            size={32}
            fontWeight={"medium"}
          >
            Final design approval
          </Typography>
          <Typography
            style={{ zIndex: 2, position: "relative" }}
            color={tokens.TINTS_OFF_WHITE_90}
            size={72}
            lineHeight={80}
            fontWeight={"regular"}
          >
            {primaryContact?.customerFirstName}'s solar project
          </Typography>
        </CapPageHeader>
      )}
      <CapPageContainer>
        <CapNav
          changeStep={(step) => changeStep(step)}
          currentStep={currentStep}
        ></CapNav>
        <ContentContainer>
          <CapPresentationContent stepName={currentStep} />
        </ContentContainer>
      </CapPageContainer>
      {(currentStep === STEPS.NEXT_STEPS ||
        (currentStep === STEPS.READ_SIGN && showAutopay)) && (
        <MobileAppContent>
          <DownloadInfo>
            <TopRow>
              <img src={sunrunMobile} alt={"sunrun mobile app"} />
              <Typography size={32} color={tokens.WHITE}>
                Install the Sunrun mobile app to track your progress
              </Typography>
            </TopRow>
            <Typography color={tokens.WHITE}>
              Newly designed and easy-to-use, our app is the single access point
              for you to monitor your system, track energy production, manage
              your account, and get support.
            </Typography>
            <Typography size={20} fontWeight={"medium"} color={tokens.WHITE}>
              Download it today.
            </Typography>
            <ImgRow>
              <img src={AppleAppStore} alt={"Apple App Store logo"} />
              <img src={DownloadAppQR} alt={"qr code to download Sunrun app"} />
              <img src={GooglePlayStore} alt={"Google Play store logo"} />
            </ImgRow>
          </DownloadInfo>
          <PhoneContainer>
            <img
              src={currentStep === STEPS.NEXT_STEPS ? iPhone : iPhoneAutopay}
              alt={
                currentStep === STEPS.NEXT_STEPS
                  ? "iPhone with Your Clean Energy Path checklist"
                  : "iphone with autopay enroll form"
              }
            />
          </PhoneContainer>
        </MobileAppContent>
      )}
      <CapPageFooter>
        <Footer>
          <Left>
            <Typography size={32} color={tokens.WHITE}>
              Take your next steps toward solar
            </Typography>
          </Left>
          <Right>
            <EmailButton onClick={() => setConfirmEmail(true)}>
              Email presentation
            </EmailButton>
            {currentStep !== STEPS.READ_SIGN && (
              <AgreementsButton onClick={() => changeStep(STEPS.READ_SIGN)}>
                Read & Sign Docs
              </AgreementsButton>
            )}
          </Right>
        </Footer>
      </CapPageFooter>

      {confirmEmail && (
        <ConfirmEmailModal
          primaryButton={{
            text: "Send Email",
            disabled: isEmailSending,
            onClick: sendProspectProposal,
          }}
          secondaryButton={{
            text: "Cancel",
            disabled: isEmailSending,
            onClick: () => setConfirmEmail(false),
          }}
          onClose={() => setConfirmEmail(false)}
        >
          {!isEmailSending ? (
            <Typography>
              Please confirm, sending email to: {prospect?.customerEmail}
            </Typography>
          ) : (
            <LoadingIndicator color="black" message="Emailing Cap proposal" />
          )}
        </ConfirmEmailModal>
      )}
    </>
  );
};

const ConfirmEmailModal = styled(Modal)``;
const CapPageContainer = styled.section`
  display: flex;
  flex-direction: column;
  padding: 40px 64px 64px;
  background: linear-gradient(
    180deg,
    ${tokens.BACKGROUND_LIGHT} 0,
    ${tokens.CHARGEBLUE_50} 60%,
    ${tokens.BACKGROUND_LIGHT} 90%
  );
  min-height: 550px;
`;

const CapPageHeader = styled.header`
  padding: 50px;
  background-image: url(${heroImg});
  background-size: cover;
  position: relative;
`;

const TriangleOverlay = styled.img`
  position: absolute;
  height: 90%;
  z-index: 1;
  bottom: 0;
  right: 0;

  @media screen and (max-width: 768px) {
    height: 50%;
  }
`;

const CapPageFooter = styled.footer`
  display: flex;
  width: 100%;
  background: ${tokens.BRAND_HERO_BLUE};

  & > Button {
    border: 1px solid white;
  }
`;

const Left = styled.div`
  display: flex;
  justify-content: flex-start;
  width: 60%;
`;

const Right = styled.div`
  display: flex;
  justify-content: flex-end;
  justify-self: flex-end;
`;

const Footer = styled.div`
  display: flex;
  gap: 30px;
  padding: 50px;
  width: 100%;
`;

const ContentContainer = styled.section``;

const EmailButton = styled(Button)`
  border: 1px solid white;
  margin-right: 40px;
  height: 62px;
  width: 182px;
`;

const AgreementsButton = styled(Button)`
  background: white;
  color: ${tokens.BRAND_HERO_BLUE};
  height: 62px;
  width: 182px;
`;

const MobileAppContent = styled.section`
  background: linear-gradient(180deg, #1f2647 0%, black 100%);
  display: flex;
  padding: 80px 80px 0;
  gap: 100px;
`;

const DownloadInfo = styled.div`
  width: 55%;
  display: flex;
  flex-direction: column;
  gap: 24px;

  & > img {
    height: 64px;
    width: 64px;
  }
`;

const TopRow = styled.div`
  display: flex;
  gap: 16px;
  flex-direction: row;

  & > p {
    line-height: 35px;
  }
`;

const ImgRow = styled.div`
  display: flex;
  gap: 13%;
  flex-direction: row;
  justify-content: space-between;
`;

const PhoneContainer = styled.div`
  display: flex;

  & > img {
    max-width: 288px;
    height: auto;
  }
`;

export { CapPage };

import React from "react";
import { Button, tokens, Typography } from "@sunrun/experience-ui-components";
import styled from "@emotion/styled/macro";
import { isArray } from "lodash";
import { CapSteps } from "../../constants/capConstants";
import { useCapContext } from "../../providers/CapContext";
import { StepName } from "../../capTypes";
import { HamburgerMenu } from "./HamburgerMenu";

type CapSidebarProps = {
  currentStep: StepName;
  changeStep: (step: StepName) => void;
};

const CapNav = ({ currentStep, changeStep }: CapSidebarProps) => {
  const goToStep = (step: StepName) => {
    changeStep(step);
  };

  const {
    cap,
    sow,
    prospect,
    sfdcUrl,
    oneBaseUrl,
    splatBaseUrl,
    reviewOfferId,
    capTask,
    equipmentLocationImages,
  } = useCapContext();

  const projectNotesUrl = `${sfdcUrl}/apex/wfproject_lwc?id=${sow?.projectId}&sfdc.override=1&c__tabName=notes`;
  const customerProfileUrl = `${oneBaseUrl}/customers/customer-profile/${prospect?.prospectId}`;
  const revisitOfferPresentation = `${splatBaseUrl}/go-solar/${prospect?.prospectId}/offer/${reviewOfferId}`;
  const capInSfdcUrl = `${sfdcUrl}/apex/wftask_lwc?id=${capTask?.id}&sfdc.override=1&c__tabName=DETAILS`;

  const fries = [
    [capInSfdcUrl, "CAP in Salesforce"],
    [capInSfdcUrl, "CAP changes needed"],
    [projectNotesUrl, "Project notes"],
    [customerProfileUrl, "Customer profile"],
    [revisitOfferPresentation, "Revisit offer presentation"],
  ];

  const filteredCapSteps = CapSteps.filter((step) => {
    return !(
      (cap?.type === "A" || cap?.type === "B") &&
      step.name === "Home readiness"
    );
  }).filter((step) => {
    return !(
      step.name === "Equipment location" &&
      !!equipmentLocationImages &&
      isArray(equipmentLocationImages) &&
      equipmentLocationImages.length < 1
    );
  });

  return (
    <SidebarNav>
      <StepsList>
        {filteredCapSteps.map((capStep) => {
          return (
            <CapNavItem
              key={capStep.name}
              onClick={() => goToStep(capStep.name)}
              style={{
                borderRadius: "unset",
                borderBottom:
                  capStep.name === currentStep
                    ? `3px solid ${tokens.BRAND_HERO_BLUE}`
                    : "none",
              }}
            >
              <Typography
                size={17}
                color={
                  capStep.name === currentStep
                    ? `${tokens.BRAND_HERO_BLUE}`
                    : `${tokens.TINTS_OFF_WHITE_20}`
                }
                lineHeight={20}
              >
                {capStep.name}
              </Typography>
            </CapNavItem>
          );
        })}
      </StepsList>
      <TenthContainer>
        <HamburgerMenu fries={fries} />
      </TenthContainer>
    </SidebarNav>
  );
};

const CapNavItem = styled(Button)`
  background: none;
  padding: 8px;
`;

const StepsList = styled.div`
  display: flex;
  gap: 24px;
  width: 90%;
`;

const SidebarNav = styled.nav`
  display: flex;
  flex-direction: row;
`;

const TenthContainer = styled.div`
  display: flex;
  width: 10%;
  justify-content: flex-end;
`;

export { CapNav };

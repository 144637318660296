import React from "react";
import {
  Card,
  SvgIcon,
  SvgNames,
  tokens,
  Typography,
} from "@sunrun/experience-ui-components";
import styled from "@emotion/styled/macro";

type PaymentType = "echeck" | "card" | "savings";

const paymentMap = {
  echeck: "Checking",
  card: "Credit Card",
  savings: "Savings",
  recurring: "Recurring",
  recurring_cc: "Recurring CC",
};

const PaymentMessageDisplay: React.FC<{
  prompt: string;
  info: string;
  bold?: boolean;
}> = ({ prompt, info }) => (
  <FlexSpreadDiv>
    <Typography size={tokens.FONT_SIZE_3} color={tokens.BRAND_HERO_BLUE}>
      {prompt}
    </Typography>
    <Typography size={tokens.FONT_SIZE_3} color={tokens.TINTS_OFF_WHITE_20}>
      {info}
    </Typography>
  </FlexSpreadDiv>
);

type SuccessfulPaymentProps = {
  autoPay: boolean;
  odiComplete?: boolean;
  customerEmail?: string;
  lastFour?: string;
  paymentAmount: string;
  paymentType?: PaymentType;
};

const SuccessfulPayment: React.FC<SuccessfulPaymentProps> = ({
  autoPay,
  odiComplete = false,
  customerEmail,
  lastFour,
  paymentAmount,
  paymentType,
}) => {
  const dateObj = new Date();

  return (
    <CustomPaddingCard>
      <div>
        <EnrolledContainer>
          <div>
            <SvgIcon
              name={SvgNames.CheckEnclosed}
              width="56"
              height="56"
              color={tokens.TINTS_SUCCESS_50}
            />
          </div>
          <Typography variant="h2">
            {autoPay ? "Enrolled in Autopay" : "Payment Confirmed"}
          </Typography>
          <div>
            <Typography>
              {autoPay
                ? "These scheduled payments will be withdrawn monthly from the date of your system's activation."
                : "Your payment has been successfully processed."}
            </Typography>
            {customerEmail && (
              <Typography>
                {autoPay
                  ? "A confirmation was emailed to "
                  : "A receipt was emailed to "}
                {customerEmail}
              </Typography>
            )}
          </div>
        </EnrolledContainer>
      </div>
      <div>
        {autoPay && (
          <>
            <PaymentMessageDisplay
              prompt="Monthly payment due date"
              info="Monthly from the date of your system's activation"
            />
            {lastFour && paymentType && (
              <PaymentMessageDisplay
                prompt="Payment method"
                info={`${paymentMap[paymentType]} account ending in ${lastFour}`}
              />
            )}
            {!lastFour && (
              <PaymentMessageDisplay
                prompt={"Payment method"}
                info={`${
                  paymentType === "card"
                    ? "Recurring credit card transaction"
                    : "Recurring ACH transaction"
                }`}
              />
            )}
            <PaymentMessageDisplay
              prompt="Monthly payment amount"
              info={paymentAmount}
            />
          </>
        )}
        {!autoPay && (
          <>
            {lastFour && (
              <>
                <PaymentMessageDisplay
                  prompt="Payment date"
                  info={`Today, ${dateObj.toLocaleDateString("en-US", {
                    month: "long",
                    day: "numeric",
                    year: "numeric",
                  })}`}
                />
                {paymentType && (
                  <PaymentMessageDisplay
                    prompt="Payment method"
                    info={`${paymentMap[paymentType]} account ending in ${lastFour}`}
                  />
                )}
              </>
            )}
            <PaymentMessageDisplay
              prompt="Payment amount"
              info={paymentAmount}
            />
          </>
        )}
      </div>
    </CustomPaddingCard>
  );
};

const CustomPaddingCard = styled(Card)`
  > div:not(.card-header, .card-footer, .card-section-divider) {
    padding: 24px 24px 0 24px;
  }
`;

const FlexSpreadDiv = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 0 -24px; // Extend horizontal lines through card padding
  padding: 16px 24px;
  border-top: 1px solid ${tokens.BORDER_LIGHT};

  @media screen and (max-width: 950px) {
    display: block;
  }
`;

const EnrolledContainer = styled.div`
  flex-direction: column;
  display: inline-flex;
  gap: 12px;
`;

export { SuccessfulPayment };

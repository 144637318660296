import styled from "@emotion/styled/macro";
import {
  Chart,
  Tabs,
  tokens,
  Typography,
} from "@sunrun/experience-ui-components";
import { ReactNode } from "react";
import { ReviewOffer } from "../../../amplify/backend/function/offerexpstoreFrontApi/ts/public/offerTypes";
import { formatCurrency } from "../../utils/formatHelpers";
import { maxWidth } from "components/atoms/GlobalStyles";
import {
  formatDataForMonthly,
  monthsAndDays,
  getMonthProperty,
} from "utils/usageUtils";
import {
  financialProductDisplayText,
  FLEX_MONTHLY,
} from "constants/financialProducts";

type Props = {
  offer: ReviewOffer;
};

const getContent = (
  isFlexMonthly: boolean,
  offer?: ReviewOffer
): {
  /** the array that defines the graphs legend content */
  legendInfo: { name: string; symbol: { type?: string; fill?: string } }[];
  /** the heading or title next to the graph */
  graphHeading: ReactNode;
  /** the description under the graph heading */
  graphDescription: string;
  /** the white dotted line data */
  areaData: { x: number; y: number; y0: 0 }[];
  /** the blue dotted line data */
  secondaryAreaData: { x: number; y: number; y0: 0 }[];
} => {
  let legendInfo: { name: string; symbol: { type?: string; fill?: string } }[];
  let graphHeading: ReactNode;
  let graphDescription: string;
  let areaData: { x: number; y: number; y0: 0 }[];
  let secondaryAreaData: { x: number; y: number; y0: 0 }[];

  if (isFlexMonthly) {
    legendInfo = [
      {
        name: "Base Solar Production",
        symbol: { type: "minus" },
      },
      {
        name: `${financialProductDisplayText[FLEX_MONTHLY]} Production`,
        symbol: { type: "minus", fill: tokens.BRAND_CHARGEBLUE },
      },
      {
        name: "Est. Energy Usage",
        symbol: { fill: tokens.BRAND_CHARGEBLUE },
      },
    ];
    graphHeading = (
      <>
        You're
        <br />
        unlocking
        <br />
        energy for
        <br />
        life
      </>
    );
    graphDescription = `Your new energy system is designed to create solar energy with the industry's best products. And with ${financialProductDisplayText[FLEX_MONTHLY]}, you are all set for your future increased electricity needs.`;
    areaData = [
      offer?.monthlyProductionBaseline?.jan ?? 0,
      offer?.monthlyProductionBaseline?.feb ?? 0,
      offer?.monthlyProductionBaseline?.mar ?? 0,
      offer?.monthlyProductionBaseline?.apr ?? 0,
      offer?.monthlyProductionBaseline?.may ?? 0,
      offer?.monthlyProductionBaseline?.jun ?? 0,
      offer?.monthlyProductionBaseline?.jul ?? 0,
      offer?.monthlyProductionBaseline?.aug ?? 0,
      offer?.monthlyProductionBaseline?.sep ?? 0,
      offer?.monthlyProductionBaseline?.oct ?? 0,
      offer?.monthlyProductionBaseline?.nov ?? 0,
      offer?.monthlyProductionBaseline?.dec ?? 0,
    ].map((value, index) => ({ x: index + 1, y: value, y0: 0 }));
    secondaryAreaData = [
      offer?.monthlyProduction.jan ?? 0,
      offer?.monthlyProduction.feb ?? 0,
      offer?.monthlyProduction.mar ?? 0,
      offer?.monthlyProduction.apr ?? 0,
      offer?.monthlyProduction.may ?? 0,
      offer?.monthlyProduction.jun ?? 0,
      offer?.monthlyProduction.jul ?? 0,
      offer?.monthlyProduction.aug ?? 0,
      offer?.monthlyProduction.sep ?? 0,
      offer?.monthlyProduction.oct ?? 0,
      offer?.monthlyProduction.nov ?? 0,
      offer?.monthlyProduction.dec ?? 0,
    ].map((value, index) => ({ x: index + 1, y: value, y0: 0 }));
  } else {
    legendInfo = [
      {
        name: "Est. Solar Production",
        symbol: { type: "minus" },
      },
      {
        name: "Est. Energy Usage",
        symbol: { fill: tokens.BRAND_CHARGEBLUE },
      },
    ];
    graphHeading = (
      <>
        Your home,
        <br />
        Your energy
      </>
    );
    graphDescription =
      "Be reassured knowing your energy needs will be met - especially during those summer months.";
    areaData = [
      offer?.monthlyProduction.jan ?? 0,
      offer?.monthlyProduction.feb ?? 0,
      offer?.monthlyProduction.mar ?? 0,
      offer?.monthlyProduction.apr ?? 0,
      offer?.monthlyProduction.may ?? 0,
      offer?.monthlyProduction.jun ?? 0,
      offer?.monthlyProduction.jul ?? 0,
      offer?.monthlyProduction.aug ?? 0,
      offer?.monthlyProduction.sep ?? 0,
      offer?.monthlyProduction.oct ?? 0,
      offer?.monthlyProduction.nov ?? 0,
      offer?.monthlyProduction.dec ?? 0,
    ].map((value, index) => ({ x: index + 1, y: value, y0: 0 }));
    secondaryAreaData = [];
  }
  return {
    legendInfo,
    graphHeading,
    graphDescription,
    areaData,
    secondaryAreaData,
  };
};

const SolarProduction = ({ offer }: Props) => {
  const isFlexMonthly = offer?.financial?.summary?.type === "FlexMonthly";

  const usage = getMonthProperty(offer?.usage);

  const production = getMonthProperty(offer?.monthlyProduction);

  const baselineProduction = getMonthProperty(offer?.monthlyProductionBaseline);

  const monthlyData = formatDataForMonthly(usage, monthsAndDays, "monthly");

  const barData = addToolTips({
    barData: monthlyData,
    production,
    isFlexMonthly,
    baselineProduction,
  });

  const getDomainSize = () => window.innerWidth / 100 + 16;
  return (
    <Container>
      <SolarTextContainer>
        <Typography
          size={tokens.FONT_SIZE_8}
          color={tokens.BRAND_HEROBLUE}
          lineHeight={53}
        >
          {getContent(isFlexMonthly).graphHeading}
        </Typography>
        <br />
        <Typography size={tokens.FONT_SIZE_2} color={tokens.BRAND_HEROBLUE}>
          {getContent(isFlexMonthly).graphDescription}
        </Typography>
      </SolarTextContainer>
      <SolarCard>
        <Chart
          xLabel="month"
          barData={barData}
          areaData={getContent(isFlexMonthly, offer).areaData}
          secondaryAreaData={getContent(isFlexMonthly, offer).secondaryAreaData}
          xLabelFormat={(label) => label?.toString().charAt(0)}
          showXGrid
          howManyXAxis={4}
          legendData={getContent(isFlexMonthly).legendInfo}
          barRatio={0.6}
          toolTipAlignment={-125}
          toolTipFontSize={18}
          useToolTip={true}
          domainPadding={getDomainSize()}
          toolTipLabelProps={{
            dx: -125,
            style: [
              {
                fontWeight: "500",
                fontFamily: "Roobert, sans-serif",
                fill: tokens.HEROBLUE_20,
                fontSize: "20px",
              },
              ...new Array(4).fill({
                fontWeight: "300",
                fontFamily: "Roobert, sans-serif",
                fill: tokens.HEROBLUE_20,
                fontSize: "16px",
              }),
            ],
          }}
          toolTipPointerLength={20}
          toolTipPointerWidth={40}
          toolTipProps={{
            constrainToVisibleArea: false,
            flyoutStyle: {
              strokeWidth: 30,
              stroke: tokens.TINTS_INFO_80,
              fill: tokens.TINTS_INFO_80,
              minWidth: 300,
            },
          }}
        />
      </SolarCard>
    </Container>
  );
};

const TargetZone = ({ offer }: Props) => {
  const isFlexMonthly = offer?.financial?.summary?.type === "FlexMonthly";

  const usage = getMonthProperty(offer?.usage);

  const production = getMonthProperty(offer?.monthlyProduction);

  const baselineProduction = getMonthProperty(offer?.monthlyProductionBaseline);

  const monthlyData = formatDataForMonthly(usage, monthsAndDays, "monthly");

  const barData = addToolTips({
    barData: monthlyData,
    production,
    isFlexMonthly,
    baselineProduction,
  });

  const getDomainSize = () => window.innerWidth / 100 + 16;
  const areaData = getContent(isFlexMonthly, offer).areaData;
  const targetDifference =
    (areaData.reduce((accumulator, { y }) => accumulator + y, 0) * 0.2) / 12;

  return (
    <Container>
      <SolarTextContainer>
        <Typography
          size={tokens.FONT_SIZE_8}
          color={tokens.BRAND_HEROBLUE}
          lineHeight={53}
        >
          Your solar production on target
        </Typography>
        <br />
        <Typography size={tokens.FONT_SIZE_2} color={tokens.BRAND_HEROBLUE}>
          It’s normal for solar production to vary from month to month due to
          changes in weather. The Target Zone in this graph shows the normal
          range of solar production for your system based on its design, local
          peer data, and seasonality of solar production.
        </Typography>
      </SolarTextContainer>
      <SolarCard>
        <Chart
          xLabel="month"
          barData={barData}
          areaData={areaData.map(({ x, y }) => ({
            x,
            y,
            y0: y - targetDifference,
          }))}
          lineData={areaData.map(({ x, y }) => ({
            x,
            y: y - targetDifference,
          }))}
          xLabelFormat={(label) => label?.toString().charAt(0)}
          showXGrid
          howManyXAxis={4}
          legendData={[
            {
              name: "Target Zone",
              symbol: {
                type: "minus",
                stroke: tokens.TINTS_SUCCESS_70,
                strokeWidth: 1,
                fillOpacity: 0.5,
                fill: tokens.TINTS_SUCCESS_70,
              },
            },
            {
              name: "Est. Energy Usage",
              symbol: { fill: tokens.BRAND_CHARGEBLUE },
            },
          ]}
          barRatio={0.6}
          toolTipAlignment={-125}
          toolTipFontSize={18}
          useToolTip={true}
          domainPadding={getDomainSize()}
          toolTipLabelProps={{
            dx: -125,
            style: [
              {
                fontWeight: "500",
                fontFamily: "Roobert, sans-serif",
                fill: tokens.HEROBLUE_20,
                fontSize: "20px",
              },
              ...new Array(4).fill({
                fontWeight: "300",
                fontFamily: "Roobert, sans-serif",
                fill: tokens.HEROBLUE_20,
                fontSize: "16px",
              }),
            ],
          }}
          toolTipPointerLength={20}
          toolTipPointerWidth={40}
          toolTipProps={{
            constrainToVisibleArea: false,
            flyoutStyle: {
              strokeWidth: 30,
              stroke: tokens.TINTS_INFO_80,
              fill: tokens.TINTS_INFO_80,
              minWidth: 300,
            },
          }}
          styles={{
            area: {
              data: {
                stroke: tokens.TINTS_SUCCESS_70,
                strokeWidth: 1,
                fillOpacity: 0.5,
                fill: tokens.TINTS_SUCCESS_70,
              },
            },
            primaryLine: {
              data: {
                stroke: tokens.TINTS_SUCCESS_70,
                strokeWidth: 1,
              },
            },
          }}
          primaryLineLabels={[""]}
        />
      </SolarCard>
    </Container>
  );
};

const addToolTips = ({
  barData,
  production,
  isFlexMonthly,
  baselineProduction,
}: {
  barData: { month: string; value: number }[];
  production: number[];
  isFlexMonthly: boolean;
  baselineProduction: number[];
}) => {
  const year = new Date().getFullYear();
  return barData.map(({ value, month }, idx) => {
    const label = createToolTip(
      value,
      production[idx],
      month,
      year,
      isFlexMonthly,
      baselineProduction[idx]
    );
    return { value, month, label };
  });
};

const createToolTip = (
  usage: number,
  production: number,
  month: string,
  year: number,
  isFlexMonthly: boolean,
  baselineProduction: number
): string[] => {
  const net = production - usage;
  const formattedProduction = Math.round(production).toLocaleString();
  const formattedBaseline = Math.round(baselineProduction).toLocaleString();
  const formattedUsage = Math.round(usage).toLocaleString();
  const formattedNet = Math.abs(Math.round(net)).toLocaleString();
  if (isFlexMonthly) {
    return [
      `${month} ${year} Estimated`,
      " ",
      `• Sunrun Flex production ${formattedProduction} kWh`,
      `• Base solar production ${formattedBaseline} kWh`,
      `• Energy usage ${formattedUsage} kWh`,
    ];
  } else {
    return [
      `${month} ${year} Estimated`,
      " ",
      `• Est. solar production ${formattedProduction} kWh`,
      `• Est. energy usage ${formattedUsage} kWh`,
      `• Energy ${net <= 0 ? "used from" : "sent to"} grid ${formattedNet} kWh`,
    ];
  }
};

const SunrunFutureBills = ({ offer }: Props) => {
  const xLabelFormatter = (label: any) => {
    return "Year " + label.toString().slice(0, 2);
  };

  const yTickFormatter = (tick: number) => {
    return formatCurrency(tick);
  };

  let maxSavingsData;
  let minSavingsData;
  let annualCumulativeCostWithoutSolar: Array<{ min: number; max: number }>;

  const totalAnnualCumCostWOSolar = () => {
    if (offer.financial.summary.future25YearPredictionData) {
      annualCumulativeCostWithoutSolar = {
        ...offer.financial.summary.future25YearPredictionData
          .annualCumulativeCostWithoutSolar,
      };

      maxSavingsData = [
        { x: 0, y: annualCumulativeCostWithoutSolar[0].max },
        { x: 5, y: annualCumulativeCostWithoutSolar[4].max },
        { x: 10, y: annualCumulativeCostWithoutSolar[9].max },
        { x: 15, y: annualCumulativeCostWithoutSolar[14].max },
        { x: 20, y: annualCumulativeCostWithoutSolar[19].max },
        { x: 25, y: annualCumulativeCostWithoutSolar[24].max },
      ];

      minSavingsData = [
        { x: 0, y: annualCumulativeCostWithoutSolar[0].min },
        { x: 5, y: annualCumulativeCostWithoutSolar[4].min },
        { x: 10, y: annualCumulativeCostWithoutSolar[9].min },
        { x: 15, y: annualCumulativeCostWithoutSolar[14].min },
        { x: 20, y: annualCumulativeCostWithoutSolar[19].min },
        { x: 25, y: annualCumulativeCostWithoutSolar[24].min },
      ];
      return annualCumulativeCostWithoutSolar;
    } else return null;
  };

  const totalAnnualCumCostResult = totalAnnualCumCostWOSolar();
  const totalSavings = totalAnnualCumCostResult
    ? {
        min: totalAnnualCumCostResult[24].min,
        max: totalAnnualCumCostResult[24].max,
      }
    : null;

  return minSavingsData && maxSavingsData ? (
    <Container>
      <SolarTextContainer>
        <Typography size={tokens.FONT_SIZE_8} color={tokens.BRAND_HEROBLUE}>
          Your cost of
          <br />
          doing
          <br />
          nothing
        </Typography>
        <br />
        <Typography size={tokens.FONT_SIZE_2} color={tokens.BRAND_HEROBLUE}>
          Without solar you could be paying&nbsp;{" "}
          {formatCurrency(totalSavings?.min)} - &nbsp;
          {formatCurrency(totalSavings?.max)} for electricity over 25 years.
        </Typography>
        <br />
        <Typography size={"extra_small"} color={tokens.BRAND_HEROBLUE}>
          *These predictions are based on historical rates from your utility,
          data from entire populations across your ZIP code, and trends in
          electricity. This is an estimate of utility costs only and does not
          represent a guarantee of savings. You will still have a bill with your
          utility company. The amount you owe your utility depends on utility
          rate schedules, fixed charges, seasonal changes in solar output, and
          if you use more power than you did in the past.
        </Typography>
      </SolarTextContainer>
      <SolarCard>
        <Chart
          xLabel="annual"
          lineData={maxSavingsData}
          primaryLineLabels={[]}
          secondaryLineData={minSavingsData}
          secondaryLineLabels={[]}
          yTickFormat={yTickFormatter}
          xLabelFormat={xLabelFormatter}
          showXGrid
          howManyXAxis={5}
          legendData={[
            {
              name: "High",
              symbol: { type: "minus" },
            },
            {
              name: "Low",
              symbol: { fill: tokens.BRAND_CHARGEBLUE },
            },
          ]}
        />
      </SolarCard>
    </Container>
  ) : (
    <div>
      Sorry, our crystal ball is having trouble seeing into this future. Please
      contact Sales Dev support - Ryne Gross
    </div>
  );
};

const graphTabs = ["Solar production", "Target Zone", "Future Utility Costs"];

const GraphContent = ({ offer }: Props) => {
  return (
    <GraphContentContainer>
      <Tabs
        tabs={graphTabs}
        contents={[
          <SolarProduction offer={offer} />,
          <TargetZone offer={offer} />,
          <SunrunFutureBills offer={offer} />,
        ]}
        initialTabIndex={0}
        onSelectTab={() => {
          // default behavior of tabs
        }}
      />
    </GraphContentContainer>
  );
};

const Container = styled.div`
  display: flex;
  padding-top: 32px;

  @media (max-width: ${maxWidth}px) {
    flex-direction: column;
  }
`;

const SolarTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  text-align: left;
  color: #1f2647;
  width: 263px;
  font-style: normal;

  @media (max-width: ${maxWidth}px) {
    width: 100%;
    margin: 0 10px;
  }
`;

const SolarCard = styled.div`
  height: 485px;
  width: auto;
  flex: 1;
  color: white;
  border: 1px solid #ffffff;
  box-sizing: border-box;
  border-radius: 8px;
  font-weight: 500;
  font-size: 16px;
  background: linear-gradient(180deg, #1f2647 0%, #000000 270.13%);
  appearance: none;
  text-align: left;
  margin-left: 35px;

  @media (max-width: ${maxWidth}px) {
    margin-left: 0px;
    margin-top: 35px;
    flex: unset;
    height: 485px;
    width: auto;
  }
`;

const GraphContentContainer = styled.div`
  margin: 64px 92px 0px 92px;

  @media (max-width: ${maxWidth}px) {
    margin: 64px 10px 0px 10px;
  }
`;

export type { Props };
export { GraphContent };
